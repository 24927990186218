import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { getBodyTypesAction } from './actions/getBodyTypes';
import { BodyTypesPage } from '../../../components/bodyTypes/list/page';
import { BodyTypesFilter } from '../../../model/filters/BodyTypesFilter';
import { exportToExcelAction } from './actions/exportToExcel';
import { Language } from '../../../model/Language';
import { deleteBodyTypeAction } from './actions/deleteBodyType';
import { getBusinessNamesAction } from './actions/getBusinessNames';
import { UserInfo } from '../../../model';

const mapStateToProps = (state: StateReducer) => ({
	bodyTypesCollection: state.bodyTypes,
	business: state.businessNames,
	user: state.user,
	currentUser: state.users,
	language: state.language,
	csvDownloadLink: state.csvDownloadLink,
	filter: state.bodyTypeFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	getBodyTypes: (filter: BodyTypesFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getBodyTypesAction(filter, pageNumber, itemsPage)),
	deleteBodyType: (filter: BodyTypesFilter, language: Language, id: number, currentUser: UserInfo, itemsPage: number) =>
		dispatch(deleteBodyTypeAction(filter, language, id, itemsPage, currentUser)),
	exportToExcel: (filter: BodyTypesFilter) => dispatch(exportToExcelAction(filter)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
});

export const BodyTypesPageContainer = connect(mapStateToProps, mapDispatchToProps)(BodyTypesPage);
