import { GenericApi, SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { BaseType, Language, UserInfo } from '../../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../../utils/extensions';
const browserHistory = useHistory;

export const saveBodyTypeAction = (bodyType: BaseType, currentLanguage: Language, currentUser: UserInfo, previousBodyType?: BaseType) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	dispatch(httpCallStartAction());
	if (bodyType.id > 0) {
		handleUpdateBodyType(bodyType, language, dispatch, currentUser, previousBodyType);
	} else {
		handleCreateBodyType(bodyType, language, dispatch, currentUser);
	}
};

const handleUpdateBodyType = (bodyType: BaseType, language: any, dispatch, currentUser: UserInfo, previousBodyType: BaseType) => {
	GenericApi.UpdateAsync<BaseType>(ApiUrls.BodyTypes, bodyType).then((result: any) => {
		if (!result.error) {
			showSuccess(language['body-types']['form']['messages']['success-update']);
			browserHistory.goBack();
			dispatch(saveBodyTypeCompleted(result));
			const trazaBodyType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Body Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado el tipo de órgano ${bodyType.id}, con los siguientes cambios: ${objectToString(compareObjects(previousBodyType, bodyType))}`,
                IdRegistro: bodyType.id.toString()
            }
            SendTraceToBBDD(trazaBodyType)
		} else {
			showError(language['body-types']['form']['messages']['error-update'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveBodyTypeCompleted(result));
		}
	});
};

const handleCreateBodyType = (bodyType: BaseType, language: any, dispatch, currentUser: UserInfo) => {
	GenericApi.AddAsync<BaseType>(ApiUrls.BodyTypes, bodyType).then((result: any) => {
		if (result > 0) {
			showSuccess(language['body-types']['form']['messages']['success']);
			browserHistory.goBack();
			dispatch(saveBodyTypeCompleted(result));
			bodyType.id = result;
			const trazaBodyType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Create Body Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el tipo de órgano: ${result}`,
                IdRegistro: result.toString(),
            }
            SendTraceToBBDD(trazaBodyType)
		} else {
			showError(language['body-types']['form']['messages']['error'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveBodyTypeCompleted(result));
		}
	});
};

const saveBodyTypeCompleted = (result: number) => ({
	type: actionTypes.SAVE_BODY_TYPE_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
