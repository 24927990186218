import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { runtimeVariables } from '../../globalEnvVariables';
import { PowerRevocationFilter, PowerSubject } from '../../model';

import { RepresentativeCollection, RepresentativeDetailCollection } from '../../model/Representative';
import { Modules } from '../../model/enums';
import { ActiveRepresentativeOrderFilter } from '../../model/filters/ActiveRepresentativeOrderFilter';
import { OrderBy } from '../../model/filters/OrderBy';
import { RepresentativeDetailFilter, RepresentativeFilter } from '../../model/filters/RepresentativeFilter';
import * as utils from '../../utils/extensions';

const moduleId = Modules.Powers;

const FilterPageAsync = async (
	filter: RepresentativeDetailFilter,
	pageNumber: number,
	itemsPage: number,
): Promise<RepresentativeDetailCollection> => {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/RecordRepresentatives`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	queryString += '&CompanyOrder=Desc';

	if (filter.PersonId > 0) {
		queryString += '&PersonId=' + filter.PersonId;
	}

	if (filter.CompanyId > 0) {
		queryString += '&CompanyId=' + filter.CompanyId + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
};

const FilterAsync = async (filter: RepresentativeFilter, pageNumber: number, itemsPage: number): Promise<RepresentativeCollection> => {

	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/Representatives`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}&moduleId=${moduleId}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveRepresentativeOrderFilter.CompanyId:
			queryString += '&CompanyOrder=';
			if (filter.CompanyOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.PowerType:
			queryString += '&PowerTypeOrder=';
			if (filter.PowerTypeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.Subject:
			queryString += '&SubjectOrder=';
			if (filter.Subject === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.Dni:
			queryString += '&DniOrder=';
			if (filter.DniOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.Protocol:
			queryString += '&ProtocoloOrder=';
			if (filter.ProtocoloOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.Inscription:
			queryString += '&InscriptionOrder=';
			if (filter.InscriptionOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.Type:
			queryString += '&TypeOrder=';
			if (filter.TypeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.State:
			queryString += '&StateOrder=';
			if (filter.StateOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRepresentativeOrderFilter.Date:
			queryString += '&DocumentDateOrder=';
			if (filter.DocumentDateOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&RepresentativeNameOrder=';
			if (filter.NameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}

	if (filter.RepresentativeId > 0) {
		queryString += '&RepresentativeId=' + filter.RepresentativeId;
	}

	if (filter.NotaryPeopleId > 0) {
		queryString += '&NotaryPeopleId=' + filter.NotaryPeopleId;
	}	

	if (!utils.isNullOrEmpty(filter.Cif)) {
		queryString += '&CompanyCif=' + encodeURIComponent(filter.Cif) + '';
	}

	if (!utils.isNullOrEmpty(filter.UniqueCode)) {
		queryString += '&CodigoUnico=' + encodeURIComponent(filter.UniqueCode) + '';
	}

	if (!utils.isNullOrEmpty(filter.Dni)) {
		queryString += '&Dni=' + encodeURIComponent(filter.Dni) + '';
	}

	if (filter.PowerId > 0) {
		queryString += '&PowerId=' + filter.PowerId + '';
	}

	if (filter.TradeId > 0) {
		queryString += '&TradeId=' + filter.TradeId + '';
	}

	if (filter.RepresentativeTradeId > 0) {
		queryString += '&RepresentativeTradeId=' + filter.RepresentativeTradeId + '';
	}

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.DepartmentId > 0) {
		queryString += '&DepartmentId=' + filter.DepartmentId + '';
	}

	if (filter.PowerExerciseId > 0) {
		queryString += '&PowerExerciseId=' + filter.PowerExerciseId + '';
	}
	if (!utils.isNullOrEmpty(filter.Protocol)) {
		queryString += '&Protocol=' + encodeURIComponent(filter.Protocol) + '';
	}

	if (!utils.isNullOrEmpty(filter.Inscription)) {
		queryString += '&Inscription=' + encodeURIComponent(filter.Inscription) + '';
	}

	if (!utils.isNullOrEmpty(filter.State) && filter.StateId !== -1) {
		queryString += '&State=' + encodeURIComponent(filter.State) + '';
	}

	if (!utils.isNullOrEmpty(filter.Pagination)) {
		queryString += '&Pagination=' + encodeURIComponent(filter.Pagination) + '';
	}

	if (!utils.isNullOrEmpty(filter.Type)) {
		queryString += '&Type=' + encodeURIComponent(filter.Type) + '';
	}

	if (!utils.isNullOrEmpty(filter.PassportOthers)) {
		queryString += '&PassportOthers=' + encodeURIComponent(filter.PassportOthers) + '';
	}

	if (filter.CountryId > 0) {
		queryString += '&CountryId=' + filter.CountryId + '';
	}

	if (filter.StateId > 0) {
		queryString += '&StateId=' + filter.StateId + '';
	}

	if (filter.RevocationDateFrom) {
		queryString += '&RevocationDateFrom=' + filter.RevocationDateFrom.toDateString();
	}

	if (filter.RevocationDateTo) {
		queryString += '&RevocationDateTo=' + filter.RevocationDateTo.toDateString();
	}

	if (filter.CreationDateFrom) {
		queryString += '&CreationDateFrom=' + filter.CreationDateFrom.toDateString();
	}

	if (filter.CreationDateTo) {
		queryString += '&CreationDateTo=' + filter.CreationDateTo.toDateString();
	}
	if (!utils.isNullOrEmpty(filter.StatusPower)) {
		queryString += '&StatusPower=' + encodeURIComponent(filter.StatusPower) + '';
	}

	if (!utils.isNullOrEmpty(filter.SubjectValue)) {
		queryString += '&Subject=' + encodeURIComponent(filter.SubjectValue) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);
	
	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
};

async function GetRepresentative(personId: number, companyId: number): Promise<RepresentativeDetailCollection> {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/GetRecordRepresentative`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?CompanyId=${companyId}&PersonId=${personId}`;
	return fetch(url, obj).then((response) => response.json());
}

async function GetRepresentativeById(personId: number): Promise<RepresentativeDetailCollection> {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/Representative`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?PersonId=${personId}`;
	return fetch(url, obj).then((response) => response.json());
}

async function ExportToPDF(baseUrl: string, filter: RepresentativeDetailFilter, totalNumberRows: number): Promise<string> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/${baseUrl}/DataPdf/Pdf`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let queryString = `?PersonId=${filter.PersonId}&NumRegister=${totalNumberRows}&PageNumber=1&HasOrder=true`;
	if (filter.CompanyId > 0) {
		queryString += '&CompanyId=' + filter.CompanyId + '';
	}
	
	return fetch(baseURL + queryString, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getPdfNames('representative_detail')),
	);
}

async function ExportToExcel(filter: RepresentativeFilter): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/ExportToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = '';

	if (filter.RepresentativeId > 0) {
		queryString += '&RepresentativeId=' + filter.RepresentativeId;
	}

	if (!utils.isNullOrEmpty(filter.Cif)) {
		queryString += '&CompanyCif=' + encodeURIComponent(filter.Cif) + '';
	}

	if (!utils.isNullOrEmpty(filter.UniqueCode)) {
		queryString += '&CodigoUnico=' + encodeURIComponent(filter.UniqueCode) + '';
	}

	if (!utils.isNullOrEmpty(filter.Dni)) {
		queryString += '&Dni=' + encodeURIComponent(filter.Dni) + '';
	}

	if (filter.PowerId > 0) {
		queryString += '&PowerId=' + filter.PowerId + '';
	}

	if (filter.TradeId > 0) {
		queryString += '&TradeId=' + filter.TradeId + '';
	}

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.DepartmentId > 0) {
		queryString += '&DepartmentId=' + filter.DepartmentId + '';
	}

	if (filter.PowerExerciseId > 0) {
		queryString += '&PowerExerciseId=' + filter.PowerExerciseId + '';
	}
	if (!utils.isNullOrEmpty(filter.Protocol)) {
		queryString += '&Protocol=' + encodeURIComponent(filter.Protocol) + '';
	}

	if (!utils.isNullOrEmpty(filter.Inscription)) {
		queryString += '&Inscription=' + encodeURIComponent(filter.Inscription) + '';
	}

	if (!utils.isNullOrEmpty(filter.State)) {
		queryString += '&State=' + encodeURIComponent(filter.State) + '';
	}

	if (!utils.isNullOrEmpty(filter.Pagination)) {
		queryString += '&Pagination=' + encodeURIComponent(filter.Pagination) + '';
	}

	if (!utils.isNullOrEmpty(filter.Type)) {
		queryString += '&Type=' + encodeURIComponent(filter.Type) + '';
	}

	if (!utils.isNullOrEmpty(filter.PassportOthers)) {
		queryString += '&PassportOthers=' + encodeURIComponent(filter.PassportOthers) + '';
	}

	if (filter.CountryId > 0) {
		queryString += '&CountryId=' + filter.CountryId + '';
	}

	if (filter.StateId > 0) {
		queryString += '&StateId=' + filter.StateId + '';
	}

	if (filter.RevocationDateFrom) {
		queryString += '&RevocationDateFrom=' + filter.RevocationDateFrom.toDateString();
	}

	if (filter.RevocationDateTo) {
		queryString += '&RevocationDateTo=' + filter.RevocationDateTo.toDateString();
	}

	if (filter.CreationDateFrom) {
		queryString += '&CreationDateFrom=' + filter.CreationDateFrom.toDateString();
	}

	if (filter.CreationDateTo) {
		queryString += '&CreationDateTo=' + filter.CreationDateTo.toDateString();
	}
	if (!utils.isNullOrEmpty(filter.StatusPower)) {
		queryString += '&StatusPower=' + encodeURIComponent(filter.StatusPower) + '';
	}

	if (!utils.isNullOrEmpty(filter.SubjectValue)) {
		queryString += '&Subject=' + encodeURIComponent(filter.SubjectValue) + '';
	}
	
	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('representatives')),
	);
}

async function DeleteAsync(id: number): Promise<boolean> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/Delete`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response: any) => response.json());
}

async function GetPowersForRevocation(revocationFilter: PowerRevocationFilter): Promise<PowerSubject[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Representatives}/PowersToRevoke?hasOrder=false&companyId=${revocationFilter.companyId}&peopleId=${revocationFilter.personId}&legalCompanyId=${revocationFilter.legalCompanyId}&powerTypeId=${revocationFilter.powerTypeId}&statePower=${revocationFilter.statePower}&isEdit=${revocationFilter.isEdit}`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let randomGuid: string = utils.generateRandomGuid(true);

    baseURL += '&moduleId=' + +moduleId + '&';
    baseURL += randomGuid;
	return fetch(baseURL, obj).then((response: any) => response.json());
}

export const RepresentativeApi = {
	FilterAsync,
	FilterPageAsync,
	GetRepresentative,
	GetRepresentativeById,
	ExportToExcel,
	ExportToPDF,
	DeleteAsync,
	GetPowersForRevocation,
};
