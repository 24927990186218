import { connect } from 'react-redux';
import { Language, UserState } from '../../../model';
import { Modules } from '../../../model/enums';
import { RepresentativeDetailFilter, RepresentativeFilter } from '../../../model/filters/RepresentativeFilter';
import { StateReducer } from '../../../reducers';
import { exportToExcelAction } from '../../representatives/list/actions/exportToExcel';
import { setRepresentativeDetailFilterAction } from '../../representatives/list/actions/setRepresentativeDetailFilter';
import { getBusinessNamesAction } from '../list/actions/getBusiness';
import { getCompaniesNamesAction } from '../list/actions/getCompaniesNamesAction';
import { getDepartmentsAction } from '../list/actions/getDepartmentsAction';
import { deleteRepresentativeAction } from './actions/deleteRepresentative';
import { getCountriesAction } from './actions/getCountries';
import { getPeopleNamesAction } from './actions/getPeople';
import { getPowerExerciseNamesAction } from './actions/getPowerExerciseNames';
import { getPowersAction } from './actions/getPowers';
import { getPowerTypeNamesAction } from './actions/getPowerTypeNames';
import { getRepresentativesAction } from './actions/getRepresentatives';
import { RepresentativesPage } from './page';
import { getNotaryPeopleNamesAction } from './actions/getNotaryPeopleNames';
import { getTradeCompaniesAction } from '../form/actions/getCompanies';

const moduleId = Modules.Powers;

const mapStateToProps = (state: StateReducer) => ({
	representatives: state.representatives,
	companies: state.companyNames,
	tradeCompanies: state.tradeCompanies,
	countries: state.countries,
	powerNames: state.powerNames,
	departments: state.departments,
	business: state.businessNames,
	powerTypeNames: state.powerTypeNames,
	powerExerciseNames: state.powerExerciseNames,
	peopleNames: state.peopleNames,
	language: state.language,
	user: state.user,
	currentUser: state.users,
	filter: state.representativeFilter,
	notaryPeopleNames: state.notaryPeopleNames
});

const mapDispatchToProps = (dispatch: any) => ({
	getRepresentatives: (filter: RepresentativeFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getRepresentativesAction(filter, pageNumber, itemsPage)),
	getCompanies: () => dispatch(getCompaniesNamesAction(moduleId)),
	getTradeCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getPowers: () => dispatch(getPowersAction()),
	getPowerTypes: () => dispatch(getPowerTypeNamesAction()),
	getPowerExerciseNames: () => dispatch(getPowerExerciseNamesAction()),
	setRepresentativeDetailFilter: (filter: RepresentativeDetailFilter) => dispatch(setRepresentativeDetailFilterAction(filter)),
	getPeopleNames: (filter: string) => dispatch(getPeopleNamesAction(filter)),
	getCountries: () => dispatch(getCountriesAction()),
	getBusinessNames: () => dispatch(getBusinessNamesAction()),
	getDepartments: () => dispatch(getDepartmentsAction()),
	exportToExcel: (filter: RepresentativeFilter) => dispatch(exportToExcelAction(filter)),
	deleteRepresentative: (filter: RepresentativeFilter, language: Language, id: number, currentUser: UserState) =>
		dispatch(deleteRepresentativeAction(filter, language, id, currentUser)),
	getNotaryPeopleNames: (filter: string) => dispatch(getNotaryPeopleNamesAction(filter))		
});

export const RepresentativesPageContainer = connect(mapStateToProps, mapDispatchToProps)(RepresentativesPage);
