import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { UserInfo } from '../../model/User';

import { AppInsights } from "applicationinsights-js";
import { runtimeVariables } from '../../globalEnvVariables';

const GetUserInfo = async (): Promise<UserInfo> => {

	AppInsights.trackTrace("FRONT - GetUserInfo - Init");

	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.Me}/Me`;

	let token = await runtimeVariables._instance.authProvider.getIdToken();

    let obj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	return fetch(baseUrl, obj).then((response: any) => response.json());
};


export const MeApi = {
    GetUserInfo
}

