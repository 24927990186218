import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../common/const/resources';
import { onlyDeedTypeModule } from '../../common/const/rolesAssignments';
import { routeUrls } from '../../common/const/routes';
import { NoResultsComponent } from '../../common/noResults/noResults';
import { UnauthorizedView } from '../../common/unauthorizedView/unauthorizedView';
import { BaseType, DeedType, DeedTypeCollection, Language, User, UserInfo, UserState } from '../../model';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { DeedTypeFilter } from '../../model/filters';
import { userRoles } from '../../utils/enums/userRolesEnum';
import * as utils from '../../utils/extensions';
import { DeedTypeFilterComponent } from './crud/deedTypeFilterComponent';
import { DeedTypeDataGrid } from './crud/deedTypeGridComponent';

export interface IProps {
	deedType: DeedType;
	deedTypeCollection: DeedTypeCollection;
	deedTypeFilter: DeedTypeFilter;
	deepTypeResponse: number | boolean;
	user: User;
	language: Language;
	deedTypeNames: BaseType[];
	currentUser: UserState;
	filter: DeedTypeFilter;
	getDeedTypePrimaries: () => Promise<DeedType[]>;
	deleteDeedType: (filter: DeedTypeFilter, language: Language, id: number, currentUser: UserInfo) => Promise<boolean>;
	exportToExcel: (filter: DeedTypeFilter) => Promise<any>;
	getDeedTypeCollection: (filter: DeedTypeFilter, pageNumber: number, itemsPage: number) => Promise<DeedTypeCollection>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	collapsed: boolean;
	actualFilter: DeedTypeFilter;
}

export class DeedTypeHome extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			collapsed: false,
			actualFilter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyDeedTypeModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });
		this.props.getDeedTypeCollection(this.props.filter, this.props.match.params['page'], this.props.filter.ItemsPage);
	}

	public componentDidUpdate(prevProps) {
		if (this.props.currentUser !== prevProps.currentUser) {
			let isAuth = utils.isInRoles(onlyDeedTypeModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getDeedTypeCollection(this.props.filter, this.props.match.params['page'], this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<div className='main-container__pageTitle'>
							<h1>{language['deedType']['title']}</h1>
							{isAdmin && (
								<Link to='/deedTypes/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['deedType']['new']}</span>
								</Link>
							)}
						</div>
						<div className='ms-Grid'>
							<DeedTypeFilterComponent
								filter={this.state.actualFilter}
								onchangeFilter={this.handleFilterChange}
								getDeedTypeCollection={this.props.getDeedTypeCollection}
								getDeedTypePrimaries={this.props.getDeedTypePrimaries}
								deedTypeCollection={this.props.deedTypeCollection}
								deedTypePrimaries={this.props.deedTypePrimaries}
								language={this.props.language}
								collapsed={this.state.collapsed}
								user={this.props.currentUser}
								itemsPage={this.state.actualFilter.ItemsPage}
							/>
							{this.props.deedTypeCollection.list?.length > 0 ?
								<DeedTypeDataGrid
									deedTypeCollection={this.props.deedTypeCollection}
									deedTypePrimaries={this.props.deedTypePrimaries}
									onchangeFilter={this.handleFilterChange}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.actualFilter}
									setDeedTypeFilter={this.props.setDeedTypeFilter}
									deleteDeedType={this.props.deleteDeedType}
									page={page}
									getDeedTypeCollection={this.props.getDeedTypeCollection}
									getDeedTypePrimaries={this.props.getDeedTypePrimaries}
									exportToExcel={this.props.exportToExcel}
								/>
								:
								<NoResultsComponent
									language={this.props.language}
									list={language['deedType']['title']}
								/>
							}
						</div>
					</>
				)}
			</>
		);
	}
	public readonly handleFilterChange = (newfilter: DeedTypeFilter): void => {
		this.setState({ actualFilter: newfilter });
		this.props.history.push(routeUrls.deedTypesPagination + 1);
	};

}
