import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { runtimeVariables } from '../../globalEnvVariables';
import { BaseNameList } from '../../model/Base';
import { CompanyBase, CompanyCollection, CompanySearch, TradeCompany } from '../../model/Company';

import { RepresentativeDetailFilter } from '../../model/filters';
import { ActiveCompanyOrderFilter } from '../../model/filters/ActiveCompanyOrderFilter';
import { CompanyFilter } from '../../model/filters/CompanyFilter';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';

const FilterAsync = async (filter: CompanyFilter, pageNumber: number, itemsPage: number, moduleId: number): Promise<CompanyCollection> => {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/GetByFilter`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}&moduleId=${moduleId}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveCompanyOrderFilter.Society:
			queryString += '&NameOrder=';
			if (filter.SocietyOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveCompanyOrderFilter.TradeSpanish:
			queryString += '&TradeSpanishOrder=';
			if (filter.TradeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveCompanyOrderFilter.TradeEnglish:
			queryString += '&TradeEnglishOrder=';
			if (filter.TradeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveCompanyOrderFilter.Cif:
			queryString += '&CIFOrder=';
			if (filter.CifOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveCompanyOrderFilter.CountrySpanish:
			queryString += '&CountrySpanishOrder=';
			if (filter.CountryOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveCompanyOrderFilter.CountryEnglish:
			queryString += '&CountryEnglishOrder=';
			if (filter.CountryOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&NameOrder=';
			if (filter.SocietyOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}

	if (filter.SocietiesId.length > 0) {
		queryString += '&CompaniesId=' + filter.SocietiesId.toString() + '';
	}

	if (filter.TradeId > 0) {
		queryString += '&TradeId=' + encodeURIComponent(filter.TradeId) + '';
	}

	if (!utils.isNullOrEmpty(filter.Cif)) {
		queryString += '&CIF=' + encodeURIComponent(filter.Cif) + '';
	}

	if (!utils.isNullOrEmpty(filter.UniqueCode)) {
		queryString += '&CodigoUnico=' + encodeURIComponent(filter.UniqueCode) + '';
	}

	if (filter.CountryId > 0) {
		queryString += '&CountryId=' + encodeURIComponent(filter.CountryId) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
};

async function GetCompanyWithTradesAsync(moduleId: number): Promise<TradeCompany[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/GetTradeCompanies?moduleId=${moduleId}`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	return fetch(baseURL, obj).then((response: any) => response.json());
}
async function GetCompaniesSearchActionAsync(filter: string): Promise<CompanySearch[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/GetCompaniesFilter?filter=${filter}`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	return fetch(baseURL, obj).then((response: any) => response.json());
}
async function GetCompanyByTradeAsync(moduleId: number, tradeId?: number): Promise<TradeCompany[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/GetTradeCompanies?moduleId=${moduleId}`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	if (tradeId !== null && tradeId > 0) {
		baseURL += '&TradeId=' + tradeId;
	}
	return fetch(baseURL, obj).then((response: any) => response.json());
}

async function ExportToExcel(filter: CompanyFilter): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/ExportToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('societies')),
	);
}

function ExportToExcelQuery(filter: CompanyFilter): string {
	let queryString: string = '';

	if (filter.SocietiesId.length > 0) {
		queryString += '&CompanyId=' + filter.SocietiesId + '';
	}

	if (filter.TradeId != null) {
		queryString += '&TradeId=' + filter.TradeId;
	}

	if (filter.Cif != null) {
		queryString += '&CIF=' + filter.Cif;
	}

	if (filter.CountryId != null) {
		queryString += '&CountryId=' + filter.CountryId;
	}

	if (!utils.isNullOrEmpty(filter.UniqueCode)) {
		queryString += '&CodigoUnico=' + encodeURIComponent(filter.UniqueCode) + '';
	}

	return queryString;
}

async function GetCompaniesByPersonId(filter: RepresentativeDetailFilter, pageNumber: number, itemsPage: number): Promise<BaseNameList> {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/CompaniesByPersonIdFilter`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let queryString = '';

	if (filter.PersonId > 0) {
		queryString += '?PersonId=' + filter.PersonId;
	}

	let url = `${baseUrl}${queryString}&NumRegister=${itemsPage}&PageNumber=${pageNumber}&HasOrder=true`;

	return fetch(url, obj).then((response) => response.json());
}
async function GetCompaniesByTradeFilter(moduleId: number): Promise<CompanyBase[]> {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/Get?moduleId=${moduleId}`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	return fetch(baseUrl, obj).then((response) => response.json());
}

export const CompanyApi = {
	FilterAsync,
	ExportToExcel,
	GetCompaniesByPersonId,
	GetCompanyWithTradesAsync,
	GetCompaniesByTradeFilter,
	GetCompanyByTradeAsync,
	GetCompaniesSearchActionAsync,
};
