import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { Department, Language, UserInfo } from '../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDepartmentAction = (department: Department, currentLanguage: Language, currentUser: UserInfo, previuousDepartment?: Department) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (department.id > 0) {
        handleUpdateDepartment(department, language, dispatch, currentUser, previuousDepartment);
    } else {
        handleCreateDepartment(department, language, dispatch, currentUser);
    }
};


const handleUpdateDepartment = (department: Department, language: any, dispatch, currentUser: UserInfo, previuousDepartment: Department) => {
    GenericApi.UpdateAsync(ApiUrls.Department, department).then((result: any) => {
        if (result.error) {
            showError(language['departments']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDepartmentCompleted(result));
        }
        else {
            showSuccess(language['departments']['messages']['success-update']);
            dispatch(saveDepartmentCompleted(result));
            const trazaDepartment = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Department",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado el departamento ${department.id}, con los siguientes cambios: ${objectToString(compareObjects(previuousDepartment, department))}`,
                IdRegistro: department.id.toString()
            }
            SendTraceToBBDD(trazaDepartment)
            browserHistory.goBack();
        }
    });
}

const handleCreateDepartment = (department: Department, language: any, dispatch, currentUser: UserInfo) => {
    GenericApi.AddAsync(ApiUrls.Department, department).then((result: any) => {
        if (result > 0) {
            showSuccess(language['departments']['messages']['success']);
            dispatch(saveDepartmentCompleted(result));
            department.id = result;
            const trazaDepartment = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Create Department",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el departamento con id: ${result}`,
                IdRegistro: department.id.toString()
            }
            SendTraceToBBDD(trazaDepartment)
            browserHistory.goBack();
        } else {
            showError(language['departments']['messages']['error'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDepartmentCompleted(result));
        }
    });
}

const saveDepartmentCompleted = (result: number) => ({
    type: actionTypes.SAVE_DEPARTMENT_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});