import * as fetch from 'isomorphic-fetch';

import { ApiUrls } from '../../common/const/api';
import * as utils from '../../utils/extensions';
import { UntranslatedBaseType } from '../../model/BaseType';
import { runtimeVariables } from '../../globalEnvVariables';

async function GetAsync(): Promise<UntranslatedBaseType[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/${ApiUrls.NotaryDocuments}/Get`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let randomGuid: string = utils.generateRandomGuid(true);

	return fetch(baseURL + randomGuid, obj).then((response) => response.json());
}

export const NotaryDataApi = {
	GetAsync,
};
