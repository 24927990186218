import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../common/const/resources';
import { onlyDepartmentsModule } from '../../common/const/rolesAssignments';
import { routeUrls } from '../../common/const/routes';
import { NoResultsComponent } from '../../common/noResults/noResults';
import { SpinnerContainer } from '../../common/spinner';
import { UnauthorizedView } from '../../common/unauthorizedView/unauthorizedView';
import { BaseType, Department, DepartmentCollection, Language, User, UserInfo, UserState } from '../../model';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { DepartmentFilter } from '../../model/filters';
import { userRoles } from '../../utils/enums/userRolesEnum';
import * as utils from '../../utils/extensions';
import { DepartmentsFilterComponent } from './crud/departmentsFilterComponent';
import { DepartamentsDataGrid } from './crud/departmentsGridComponent';

export interface IProps {
	department: Department;
	departmentCollection: DepartmentCollection;
	departmentFilter: DepartmentFilter;
	departmentSave: number | boolean;
	departmentDelete: number | boolean;
	user: User;
	language: Language;
	business: BaseType[];
	currentUser: UserState;
	filter: DepartmentFilter;
	getBusiness: () => Promise<BaseType[]>;
	deleteDepartment: (filter: DepartmentFilter, language: Language, id: number, currentUser: UserInfo, department: string, business: string) => Promise<boolean>;
	exportToExcel: (filter: DepartmentFilter) => Promise<any>;
	getDepartment: (id: number) => Department;
	getDepartmentCollection: (filter: DepartmentFilter, pageNumber: number, itemsPage: number) => Promise<DepartmentCollection>;
	saveDepartment: (department: Department, language: Language, currentUser: UserInfo, previousDepartment?: Department) => Promise<number>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	collapsed: boolean;
	filter: DepartmentFilter;
}

export class DepartmentsHome extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			collapsed: false,
			filter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyDepartmentsModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });
		this.props.getDepartmentCollection(this.props.filter, this.props.match.params['page'], this.props.filter.ItemsPage);
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlyDepartmentsModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		if (this.props.match.params['page'] !== prevProps.match.params['page']) {
			this.props.getDepartmentCollection(this.props.filter, this.props.match.params['page'], this.props.filter.ItemsPage);
		}
	}
	public render() {
		let page: number = Number(this.props.match.params['page']);
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['departments']['title']}</h1>
							{isAdmin && (
								<Link to='/departments/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['departments']['new']}</span>
								</Link>
							)}
						</div>
						<div className='ms-Grid'>
							<DepartmentsFilterComponent
								filter={this.state.filter}
								onchangeFilter={this.handleFilterChange}
								departmentCollection={this.props.departmentCollection}
								business={this.props.business}
								getDepartmentCollection={this.props.getDepartmentCollection}
								language={this.props.language}
								collapsed={this.state.collapsed}
								user={this.props.currentUser}
								itemsPage={this.state.filter.ItemsPage}
							/>
							{this.props.departmentCollection.list?.length > 0 ? (
								<DepartamentsDataGrid
									departmentCollection={this.props.departmentCollection}
									onchangeFilter={this.handleFilterChange}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.filter}
									deleteDepartment={this.props.deleteDepartment}
									page={page}
									getDepartmentCollection={this.props.getDepartmentCollection}
									exportToExcel={this.props.exportToExcel}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['departments']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}
	public readonly handleFilterChange = (newfilter: DepartmentFilter): void => {
		this.props.history.push(routeUrls.departmentsPagination + 1);
	};
}
