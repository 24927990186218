import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../common/const/resources';
import { onlyPositionTypesModule } from '../../common/const/rolesAssignments';
import { routeUrls } from '../../common/const/routes';
import { NoResultsComponent } from '../../common/noResults/noResults';
import { SpinnerContainer } from '../../common/spinner';
import { UnauthorizedView } from '../../common/unauthorizedView/unauthorizedView';
import { BaseType, Language, PositionType, PositionTypeCollection, User, UserInfo, UserState } from '../../model';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { PositionTypeFilter } from '../../model/filters';
import { userRoles } from '../../utils/enums/userRolesEnum';
import * as utils from '../../utils/extensions';
import { PositionTypesFilterComponent } from './crud/positionTypesFilterComponent';
import { PositionTypesDataGrid } from './crud/positionTypesGridComponent';

export interface IProps {
	positionType: PositionType;
	positionTypeCollection: PositionTypeCollection;
	positionTypeFilter: PositionTypeFilter;
	positionTypeSave: number | boolean;
	postitionTypeDelete: number | boolean;
	user: User;
	language: Language;
	business: BaseType[];
	currentUser: UserState;
	filter: PositionTypeFilter;
	getBusiness: () => Promise<BaseType[]>;
	deletePositionType: (
		filter: PositionTypeFilter,
		language: Language,
		id: number,
		currentUser: UserState,
		positionType: string,
		business: string,
	) => Promise<boolean>;
	exportToExcel: (filter: PositionTypeFilter) => Promise<any>;
	getPositionType: (id: number) => PositionType;
	getPositionTypeCollection: (filter: PositionTypeFilter, pageNumber: number, itemsPage: number) => Promise<PositionTypeCollection>;
	setPositionTypeFilter: (filter: PositionTypeFilter) => PositionTypeFilter;
	savePositionType: (positionType: PositionType, language: Language, currentUser: UserInfo, previousPositionType?: PositionType) => Promise<number>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	collapsed: boolean;
	actualFilter: PositionTypeFilter;
}

export class PositionTypesHome extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			collapsed: false,
			actualFilter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyPositionTypesModule);
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });
		this.props.getBusiness();

		if (this.props.location.state != null) {
			this.props.getPositionTypeCollection(this.props.location.state.positionTypeFilter, page, this.props.filter.ItemsPage);
		} else {
			this.props.getPositionTypeCollection(this.props.filter, page, this.props.filter.ItemsPage);
		}
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlyPositionTypesModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getPositionTypeCollection(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}
	public render() {
		let page: number = Number(this.props.match.params['page']);
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['position-types']['title']}</h1>
							{isAdmin && (
								<Link to='/positionTypes/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['position-types']['new']}</span>
								</Link>
							)}
						</div>
						<div className='ms-Grid'>
							<PositionTypesFilterComponent
								filter={this.state.actualFilter}
								onchangeFilter={this.handleFilterChange}
								positionTypeCollection={this.props.positionTypeCollection}
								business={this.props.business}
								getPositionTypeCollection={this.props.getPositionTypeCollection}
								language={this.props.language}
								collapsed={this.state.collapsed}
								user={this.props.currentUser}
								itemsPage={this.state.actualFilter.ItemsPage}
							/>
							{this.props.positionTypeCollection.list?.length > 0 ? (
								<PositionTypesDataGrid
									positionTypeCollection={this.props.positionTypeCollection}
									onchangeFilter={this.handleFilterChange}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.actualFilter}
									setPositionTypeFilter={this.props.setPositionTypeFilter}
									deletePositionType={this.props.deletePositionType}
									page={page}
									getPositionTypeCollection={this.props.getPositionTypeCollection}
									exportToExcel={this.props.exportToExcel}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['position-types']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}
	public readonly handleFilterChange = (newfilter: PositionTypeFilter): void => {
		this.setState({ actualFilter: newfilter });
		this.props.history.push(routeUrls.positionTypesPagination + 1);
	};
}
