import { GenericApi, SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { Language, NotaryPeople, UserInfo, UserState } from '../../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../../utils/extensions';
const browserHistory = useHistory;


export const saveNotaryPeopleAction = (notary: NotaryPeople, currentLanguage: Language, currentUser: UserInfo, previousNotaryPeople?: NotaryPeople) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    dispatch(httpCallStartAction());
    if (notary.id > 0) {
        handleUpdateNotaryPeople(notary, language, dispatch, currentUser, previousNotaryPeople);
    } else {
        handleCreateNotaryPeople(notary, language, dispatch, currentUser);
    }
};

const handleUpdateNotaryPeople = (notary: NotaryPeople, language: any, dispatch, currentUser: UserInfo, previousNotaryPeople: NotaryPeople) => {
    GenericApi.UpdateAsync(ApiUrls.NotaryPeople, notary).then((result: any) => {
        if (result.error) {
            showError(language['notaryPeople']['form']['messages']['error-update'] + language['generic']['error'][result.error]);
            dispatch(saveNotaryPeopleCompleted(result));
        } else {
            showSuccess(language['notaryPeople']['form']['messages']['success-update']);
            dispatch(saveNotaryPeopleCompleted(result));
            const trazaNotaryPeople = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Notary People",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado el notario ${notary.id}, con los siguientes cambios: ${objectToString(compareObjects(previousNotaryPeople, notary))}`,
                IdRegistro: notary.id.toString()
            }
            SendTraceToBBDD(trazaNotaryPeople)
            browserHistory.goBack();
        }
    });
}

const handleCreateNotaryPeople = (notary: NotaryPeople, language: any, dispatch, currentUser: UserInfo) => {
    GenericApi.AddAsync(ApiUrls.NotaryPeople, notary).then((result: any) => {
        if (result > 0) {
            showSuccess(language['notaryPeople']['form']['messages']['success']);
            dispatch(saveNotaryPeopleCompleted(result));
            notary.id = result;
            const trazaNotaryPeople = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Create Notary People",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el notario con id: ${result}`,
                IdRegistro: result.toString(), 
                RegistroActual: objectToString(notary),
            }
            dispatch(SendTraceToBBDD(trazaNotaryPeople))
            browserHistory.goBack();

        } else {
            showError(language['notaryPeople']['form']['messages']['error'] + language['generic']['error'][result.error]);
            dispatch(saveNotaryPeopleCompleted(result));
        }
    });
}

const saveNotaryPeopleCompleted = (result: number) => ({
    type: actionTypes.SAVE_NOTARYPERSON_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});