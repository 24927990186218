import * as React from 'react';
import { TextField } from 'office-ui-fabric-react';
import * as utils from '../../../../../utils/extensions';
import { Stockholder, Language, TradeCompany, createEmptyStockholder } from '../../../../../model';
import { MessageBarComponent } from '../../../../../common/messageBar/messageBarComponent';
import { MessageBarType, IComboBoxOption, ComboBox } from 'office-ui-fabric-react';
import { ActiveLanguage } from '../../../../../model/enums/activeLanguage';

interface IProps {
	stockholders: Stockholder[];
	companies: TradeCompany[];
	language: Language;
	stockholder: Stockholder;
	addStockholder: (stockholders: Stockholder, oldStockholder: Stockholder) => void;
	isReadOnly: boolean;
	maxShares: number;
	isEdit: boolean;
	index: number;
	updateStockholder: (stockholder: Stockholder) => void;
	setStockholder: (stockholder: Stockholder) => void;
	cleanStockholderEdit: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
}

interface IState {
	stockholder: Stockholder;
	errorMessages: string[];
	trazas:string[];
}

export class ActualStockholdersForm extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			stockholder: this.props.stockholder != null ? this.props.stockholder : createEmptyStockholder(),
			errorMessages: [],
			trazas: []
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.stockholder !== prevProps.stockholder) {
			let stockholder = { ...this.state.stockholder };
			stockholder.id = this.props.stockholder.id;
			stockholder.companyStockholderId = this.props.stockholder.companyStockholderId;
			stockholder.numberStock = this.props.stockholder.numberStock;
			stockholder.numberingStock = this.props.stockholder.numberingStock;
			stockholder.operationType = this.props.stockholder.operationType;
			stockholder.percentageStock = this.props.stockholder.percentageStock;
			stockholder.startNumbering = this.props.stockholder.startNumbering;
			this.setState({ stockholder: stockholder});
		}
	}
	public render() {
		let numbering: string =
			this.state.stockholder.startNumbering > 0 && this.state.stockholder.numberStock > 0
				? this.state.stockholder.startNumbering +
				'-' +
				(this.state.stockholder.startNumbering + (this.state.stockholder.numberStock - 1))
				: '';
		

		let locale = this.props.language.name.includes(ActiveLanguage.English) ? 'en-EN' : 'de-DE';
		let participation: string =
			this.state.stockholder.numberStock > 0 && this.props.maxShares > 0
				? ((this.state.stockholder.numberStock / this.props.maxShares) * 100).toLocaleString(locale, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})
				: '';
				let language = utils.getResourcesFile(this.props.language);

		let companies: IComboBoxOption[] = [];
		if (this.props.companies) {
			this.props.companies.forEach((item) => {
				companies.push({ key: item.id, text: item.name });
			});
		}

		let validationErrors = [];
		this.state.errorMessages.forEach((item) => {
			validationErrors.push(<MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />);
		});

		let operations: IComboBoxOption[] = [
			{
				key: '1',
				text: language['deeds']['form']['sections']['actual-shareholders']['fields']['operation-type']['options']['buy'],
			},
			{
				key: '2',
				text: language['deeds']['form']['sections']['actual-shareholders']['fields']['operation-type']['options']['sell'],
			},
		];
		return (
			<div className='stockholderForm'>
				<div className='ms-Grid-row stockholderForm_inputs stockholderForm_inputs_border'>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['actual-shareholders']['fields']['society']['title']}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={companies}
							placeholder={language['authorizations']['list']['filter']['options']['society']['placeholder']}
							onChange={this.handleCompanyChange}
							selectedKey={this.state.stockholder.companyStockholderId}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['actual-shareholders']['fields']['shares-number']['title']}</p>
						<TextField
							type='text'
							className=' main-container__content_edit_block-inner'
							value={this.state.stockholder.numberStock > 0 ? this.state.stockholder.numberStock.toString() : ''}
							onChange={this.handleNumberStockChange}
							disabled={this.props.isReadOnly || this.props.maxShares < 1}
							maxLength={11}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['actual-shareholders']['fields']['numeration-start']['title']}</p>
						<TextField
							type='text'
							className=' main-container__content_edit_block-inner'
							value={this.state.stockholder.startNumbering > 0 ? this.state.stockholder.startNumbering.toString() : ''}
							onChange={this.handleStartNumberingChange}
							disabled={this.props.isReadOnly || this.props.maxShares < 1}
							maxLength={11}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['actual-shareholders']['fields']['shares-numeration']['title']}</p>
						<TextField
							type='text'
							className=' main-container__content_edit_block-inner'
							disabled={true}
							value = {utils.isNullOrEmpty(numbering) ? '' : numbering}
							onChange={this.handleNumberingChange}
							maxLength={50}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['actual-shareholders']['fields']['participation']['title']}</p>
						<TextField
							type='text'
							className=' main-container__content_edit_block-inner'
							disabled={true}
							value = {utils.isNullOrEmpty(participation) ? '' : participation}
							onChange={this.handlePercentageChange}
							maxLength={50}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['actual-shareholders']['fields']['operation-type']['title']}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={operations}
							placeholder={
								language['deeds']['form']['sections']['actual-shareholders']['fields']['operation-type']['placeholder']
							}
							onChange={this.handleOperationTypeChange}
							selectedKey={this.state.stockholder.operationType}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div className='ms-Grid-col main-container__content_edit_block errors-container'>
						<MessageBarComponent
							Message={language['deeds']['form']['sections']['actual-shareholders']['max-shares-filled']}
							MessageVisible={this.props.maxShares === 0}
							Type={MessageBarType.warning}
							close={null}
						/>
						{validationErrors}
					</div>
				</div>
				<div className='ms-Grid-row stockholderForm_buttons' style={{ display: this.props.isReadOnly ? 'none' : '' }}>
					<div className='ms-Grid-col ms-md12 main-container__content_edit_block'>
						<button type='button' 
						name='button' 
						className='button-primary' 
						onClick={this.props.isEdit ? this.handleUpdateClick : this.handleAddClick}
						>
							{this.props.isEdit
								? language['deeds']['form']['updateStockholder']
								: language['deeds']['form']['assignStockholder']}
						</button>
						<button type='button' name='button' className='button-cancel' onClick={this.cleanStockholder}>
							{language['deeds']['form']['cleanForm']}
						</button>
					</div>
				</div>
			</div>
		);
	}

	private readonly cleanStockholder = () => {
		let resetStockholder = createEmptyStockholder();
		this.setState({ stockholder: resetStockholder });
	};

	private readonly handleAddClick = () => {
		let stockholder = { ...this.state.stockholder };
		let oldStockholder = this.props.stockholder;
		if (this.validateForm(stockholder)) {
			const trazas = `Se ha añadido un accionista con los siguientes valores: ${this.state.trazas}`
			stockholder.trazas = trazas;
			this.props.addStockholder(stockholder, oldStockholder);
			this.setState({ errorMessages: [] });
		}
	};

	private readonly handleUpdateClick = () => {
		let stockholders = { ...this.state.stockholder };

		if (this.validateForm(stockholders)) {
			const trazas = `Se ha añadido un accionista con los siguientes valores: ${this.state.trazas}`
			stockholders.trazas = trazas;
			this.props.updateStockholder(stockholders);
			this.props.setFinishedEdit(true);
			this.setState({ errorMessages: [] });
		}	
	};
	
	private validateForm(stockholder: Stockholder) {
		let isStockholderValid = true;
		let validationMessages = [];
		let language = utils.getResourcesFile(this.props.language);

		let stockholders = this.props.stockholders ? this.props.stockholders.slice() : [];
		if(this.props.isEdit){
			stockholders.splice(this.props.index, 1);
		}
		let totalShares = stockholders.reduce(function(prev, cur) {
			return prev + cur.numberStock;
		  }, 0);

		let isCompanyValid = stockholder.companyStockholderId > 0;
		let haveShares = stockholder.numberStock > 0;
		let areSharesAvailables = stockholder.numberStock + totalShares <= this.props.maxShares;
		
		if(!isCompanyValid){
			validationMessages.push(language['generic']['messages']['required-society']);
		}

		if(!haveShares){
			validationMessages.push(language['generic']['messages']['required-shares']);
		}

		if(!areSharesAvailables){
			let available = this.props.maxShares - totalShares;
			validationMessages.push(language['generic']['messages']['shares-not-available'] + available);
		}
		this.setState({ errorMessages: validationMessages });
		isStockholderValid = isCompanyValid && areSharesAvailables && haveShares;
		return isStockholderValid;
	}

	private readonly handleNumberingChange = (event) => {
		let stockholder = { ...this.state.stockholder };
		stockholder.numberingStock = event.target.value.toString();
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Numeración acciones : ${event.target.value}\n`);
		this.setState({ stockholder: stockholder, trazas: trazas });
		this.props.setStockholder(stockholder);
	};

	private readonly handlePercentageChange = (event) => {
		let stockholder = { ...this.state.stockholder };
		stockholder.percentageStock = event.target.value.toString();

		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Inicio de la numeración : ${event.target.value}\n`);

		this.setState({ stockholder: stockholder, trazas: trazas });
		this.props.setStockholder(stockholder);
	};

	private readonly handleCompanyChange = (event, option): void => {
		let stockholder = { ...this.state.stockholder };
		stockholder.companyStockholderId = option?.key;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Sociedad : ${option.text}\n`);
		this.setState({ stockholder: stockholder, trazas: trazas });
		this.props.setStockholder(stockholder);
	};

	private readonly handleNumberStockChange = (event) => {
		let locale = this.props.language.name.includes(ActiveLanguage.English) ? 'en-EN' : 'de-DE';

		let stockholder = { ...this.state.stockholder };
		
		let value = utils.isNullOrEmpty(event.target.value) ? null : parseInt(event.target.value);
		let stockholders : Stockholder[] = this.props.stockholders ? this.props.stockholders.slice(): [];
		if(this.props.isEdit){
			stockholders.splice(this.props.index, 1);
		}
		let totalShares = stockholders.reduce(function(prev, cur) {
			return prev + cur.numberStock;
		  }, 0);

		let maxShares = this.props.maxShares - totalShares;
		stockholder.numberStock = value > maxShares ? stockholder.numberStock : value;
		let participation: string =
		stockholder.numberStock > 0 && this.props.maxShares > 0
			? ((stockholder.numberStock / this.props.maxShares) * 100).toLocaleString(locale, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})
			: '';
			let numbering: string =
			this.state.stockholder.startNumbering > 0 && this.state.stockholder.numberStock > 0
				? this.state.stockholder.startNumbering +
				'-' +
				(this.state.stockholder.startNumbering + (this.state.stockholder.numberStock - 1))
				: '';

			stockholder.percentageStock = participation;
			stockholder.numberingStock=numbering;

		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Número de acciones : ${event.target.value}\n`);
		this.setState({ stockholder: stockholder, trazas: trazas });
		this.props.setStockholder(stockholder);
	};

	private readonly handleStartNumberingChange = (event) => {
		let stockholder = { ...this.state.stockholder };
		stockholder.startNumbering = utils.isNullOrEmpty(event.target.value) ? null : parseInt(event.target.value);
		let numbering: string =
			this.state.stockholder.startNumbering > 0 && this.state.stockholder.numberStock > 0
				? this.state.stockholder.startNumbering +
				'-' +
				(this.state.stockholder.startNumbering + (this.state.stockholder.numberStock - 1))
				: '';
				stockholder.numberingStock = numbering;

		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Inicio de la numeración : ${event.target.value}\n`);
		this.setState({ stockholder: stockholder, trazas: trazas });
		this.props.setStockholder(stockholder);
		};

	private readonly handleOperationTypeChange = (event, option) => {
		let stockholder = { ...this.state.stockholder };
		stockholder.operationType = option?.key;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Tipo Operación : ${option.text}\n`);
		this.setState({ stockholder: stockholder });
		this.props.setStockholder(stockholder);
	};
}
