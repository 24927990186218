import { ComboBox, DatePicker, DayOfWeek, IComboBoxOption, IDatePickerStrings, MessageBarType } from 'office-ui-fabric-react';
import * as React from 'react';
import { MessageBarComponent } from '../../../../../common/messageBar/messageBarComponent';
import { SearchPeople } from '../../../../../common/search/searchPeople';
import { SearchCompany } from '../../../../../common/search/searchCompany';

import {
	BaseName,
	BaseOptionModel,
	BaseType,
	CompanySearch,
	createEmptyCompanySearch,
	createEmptyPowerRepresentativeDetail,
	Language,
	Person,
	PowerRepresentativeDetail,
	PowerRevocationFilter,
	PowerSubject,
	RepresentativeState,
	SearchSelectModel,
} from '../../../../../model';
import { ActiveLanguage } from '../../../../../model/enums/activeLanguage';
import '../../../../../styles/pages/representatives.scss';
import * as utils from '../../../../../utils/extensions';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { ButtonGroupComponent } from '../../../../../common/buttonGroup/buttonGroup';
import { languages } from '../../../../../common/const/resources';
import { isArray } from '@pnp/common';

interface IProps {
	people: Person[];
	powerTypes: BaseType[];
	language: Language;
	selectedOptions: BaseName[];
	departments: BaseType[];
	powerExerciseNames: BaseType[];
	powerTypeId: number;
	isReadOnly: boolean;
	representative: PowerRepresentativeDetail;
	powerSubjects: PowerSubject[];
	documentDate: Date;
	powerRevocationFilter: PowerRevocationFilter;
	isEdit: boolean;
	companiesSearch:CompanySearch[];
	trazas: string;
	getPowerSubjects: (filter: PowerRevocationFilter) => void;
	addRepresentative: (representative: PowerRepresentativeDetail) => void;
	getPeopleNames: (filter: string) => void;
	getCompaniesSearch: (filter: string) => void;
	updateRepresentative: (representative: PowerRepresentativeDetail, trazas: string) => void;
	setRepresentative: (representative: PowerRepresentativeDetail) => void;
	cleanRepresentativeEdit: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
	isAnyRepresentative: boolean;
}

interface IState {
	representative: PowerRepresentativeDetail;
	selectedOptions: BaseName[];
	selectedPerson: any;
	selectedNotary: any;
	selectedCompany: CompanySearch;
	errorMessages: string[];
	editMode: boolean;
	isPhysical: boolean;
	trazas: string[]
}

export class RepresentativesForm extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			representative: this.props.representative != null ? this.props.representative : createEmptyPowerRepresentativeDetail(),
			selectedOptions: this.props.selectedOptions,
			selectedPerson: null,
			selectedNotary: null,
			errorMessages: [],
			editMode: this.props.isEdit,
			isPhysical:true,
			selectedCompany:createEmptyCompanySearch(),
			trazas:[]
		};
	}

	componentDidUpdate(prevProps) {
		
		if (this.props.representative !== prevProps.representative) {
			let representative = { ...this.state.representative };
			let selectedPerson = null;
			let selectedCompany= null;

			if (this.props.representative.personId > 0) {
				selectedPerson = { value: this.props.representative.personId, label: this.props.representative.personName };
				this.getPowersToRevoke(this.props.representative.personId, this.props.representative.legalCompanyId);
				this.setState({isPhysical: true});
			}
			if (this.props.representative.legalCompanyId > 0) {
				selectedCompany = { value: this.props.representative.legalCompanyId, label: this.props.representative.legalCompanyName };
				this.getPowersToRevoke(this.props.representative.personId, this.props.representative.legalCompanyId);
				this.setState({isPhysical: false});
			}
		
			if(this.props.powerSubjects !== prevProps.powerSubjects && this.props.powerSubjects !== null && this.props.powerSubjects.length === 1){
				representative.powerToRevokeId = this.props.powerSubjects[0].id;
			}

			representative.id = this.props.representative.id;
			representative.powerExcerciseId = this.props.representative.powerExcerciseId;
			representative.departmentId = this.props.representative.departmentId;
			representative.powerTypeId = this.props.powerTypeId;
			representative.state = this.props.representative.state;
			representative.personId = this.props.representative.personId;
			representative.personName = this.props.representative.personName;
			representative.legalCompanyId = this.props.representative.legalCompanyId;
			representative.legalCompanyName = this.props.representative.legalCompanyName;
			representative.powerToRevokeId = representative.powerToRevokeId !== 0 ? representative.powerToRevokeId : this.props.representative.powerToRevokeId;
			representative.revokeOn = this.props.representative.revokeOn ? utils.getParsedDate(this.props.representative.revokeOn) : null;
			this.setState({ representative: representative, selectedPerson: selectedPerson, selectedCompany:selectedCompany });

		}
		if (this.props.powerTypeId !== prevProps.powerTypeId) {
			let representative = { ...this.state.representative };
			representative.powerToRevokeId = 0;
			this.setState({ representative });
		}
		
		if(this.props.powerSubjects !== prevProps.powerSubjects && this.props.powerSubjects !== null && this.props.powerSubjects.length === 1){
			let representative = { ...this.state.representative };
			representative.powerToRevokeId = this.props.powerSubjects[0].id;
			this.setState({ representative });
		}
	}


	public render() {
		let language = utils.getResourcesFile(this.props.language);
		//TODO: sustituir por el enumerado
		let grantRevokeCombo: IComboBoxOption[] = [];
		grantRevokeCombo.push({
			key: 2,
			text: language['representatives']['newform']['grantRevokeType']['options']['revoke'],
		});
		grantRevokeCombo.push({
			key: 1,
			text: language['representatives']['newform']['grantRevokeType']['options']['consent'],
		});
		
		let calendarStrings: IDatePickerStrings = language['calendar'];

		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});
		let exercises: IComboBoxOption[] = [];
		this.props.powerExerciseNames.forEach((item) => {
			if (this.props.language.name.includes(ActiveLanguage.English)) {
				exercises.push({ key: item.id, text: item.descriptionEnglish });
			} else {
				exercises.push({ key: item.id, text: item.descriptionSpanish });
			}
		});

		let departments: IComboBoxOption[] = [];
		this.props.departments.forEach((item) => {
			if (this.props.language.name.includes(ActiveLanguage.English)) {
				departments.push({ key: item.id, text: item.descriptionEnglish });
			} else {
				departments.push({ key: item.id, text: item.descriptionSpanish });
			}
		});

		let subjects: IComboBoxOption[] = [];
		this.props.powerSubjects.forEach((item) => {
			subjects.push({
				key: item.id,
				text: `${item.protocol ? item.protocol + '|' : ''} ${item.documentDate ? utils.formatDate(new Date(item.documentDate), this.props.language) : ''
					}`,
			});
		});

		let selectedPerson = { value: this.state.representative?.personId, label: this.state.representative?.personName };
		let selectedCompany = { value: this.state.representative?.legalCompanyId, label: this.state.representative.legalCompanyName };
	

		let options: BaseOptionModel[] = new Array(2);
		options.push({ label: language['NotarialPersonType']['natural'], value: 'true' });
		options.push({ label: language['NotarialPersonType']['legal'], value: 'false' });

		return (
			<div className='representativeForm'>
				<div className='ms-Grid-row representativeForm_inputs representativeForm_inputs_border'>
					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['newform']['isPhysical']}</p>
								<ButtonGroupComponent
									onChange={this.handleIsPersonChange}
									options={options}
									value={this.state.isPhysical.toString()}
									className={'custom-toggle-buttons'}
									disabled={this.props.isReadOnly}
								/>
						</div>
						{this.state.isPhysical ?
							<div className='ms-Grid-col main-container__content_edit_block peoplePickerContainer'>
								<SearchPeople
									title={language['representatives']['newform']['representative']['title']}
									placeholder={language['representatives']['newform']['representative']['placeholder']}
									language={this.props.language}
									selectedOption={utils.isNullOrEmpty(selectedPerson.label) ? null : selectedPerson}
									loadOptions={this.loadOptions}
									handleNameChange={this.handlePersonChange}
									getUsers={this.props.getPeopleNames}
									disabled={this.props.isReadOnly}
									onClearValue={this.onClearRepresentative}
									showDeleteButton={true}
								/>
							</div>
							:
							<div className='ms-Grid-col main-container__content_edit_block peoplePickerContainer'>
								<SearchCompany
									title={language['representatives']['newform']['legalRepresentative']['title']}
									placeholder={language['representatives']['newform']['legalRepresentative']['placeholder']}
									language={this.props.language}
									selectedOption={utils.isNullOrEmpty(selectedCompany.label) ? null : selectedCompany}
									loadOptions={this.loadOptionsCompanies}
									handleNameChange={this.handleCompanyChange}
									getCompaniesSearch={this.props.getCompaniesSearch}
									disabled={this.props.isReadOnly}
									showDeleteButton={true}
									onClearValue={this.onClearCompany}
								/>
							</div>
						}

					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['newform']['exerciseType']['title']}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={exercises}
							placeholder={language['representatives']['newform']['exerciseType']['placeholder']}
							onChange={this.handleExerciseTypeChange}
							selectedKey={this.state.representative.powerExcerciseId}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>

					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['newform']['grantRevokeType']['title'] + '*'}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={grantRevokeCombo}
							placeholder={language['representatives']['newform']['grantRevokeType']['placeholder']}
							onChange={this.handleGrantRevokeChange}
							selectedKey={this.state.representative.state}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div
						className='ms-Grid-col main-container__content_edit_block'
						style={{ display: this.state.representative.state !== RepresentativeState.Revocación ? 'none' : '' }}
					>
						<p>{language['representatives']['newform']['powerRevoke']['title'] + '*'}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={subjects}
							placeholder={language['representatives']['newform']['powerRevoke']['placeholder']}
							onChange={this.handlePowerToRevokeChange}
							selectedKey={this.state.representative.powerToRevokeId}
							// selectedKey={subjects.length==1?subjects[0].key:this.state.representative.powerToRevokeId}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					{this.state.representative.state !== RepresentativeState.Revocación ? (
						<div className='ms-Grid-col main-container__content_edit_block'>
							<p>{language['representatives']['newform']['endDate']}</p>
							<div className='range-picker datepickerContainer'>
								<DatePicker
									formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
									className={'full-width'}
									firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
									allowTextInput={true}
									parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
									placeholder={language['generic']['date']}
									strings={calendarStrings}
									value={this.state.representative.revokeOn}
									onSelectDate={this.handleDocumentDateChange}
									disabled={this.props.isReadOnly}
								/>
								<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.onClearDate()} />
							</div>
						</div>
					) : null}

					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['newform']['departments']['title']}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={departments.sort((a, b) => a.text.localeCompare(b.text))}
							placeholder={language['representatives']['newform']['departments']['placeholder']}
							onChange={this.handleDepartmentChange}
							selectedKey={this.state.representative.departmentId}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div className='ms-Grid-col main-container__content_edit_block errors-container'>{validationErrors}</div>
				</div>
				<div className='ms-Grid-row representativeForm_buttons' style={{ display: this.props.isReadOnly ? 'none' : '' }}>
					<div className='ms-Grid-col ms-md12 main-container__content_edit_block'>
						<button
							type='button'
							name='button'
							className='button-primary'
							onClick={this.props.isEdit ? this.handleUpdateClick : this.handleAddClick}
						>
							{this.props.isEdit
								? language['representatives']['newform']['updateRepresentative']
								: language['representatives']['newform']['assignRepresentative']}
						</button>
						<button type='button' name='button' className='button-cancel' onClick={this.cleanRepresentativeDetail}>
							{language['representatives']['newform']['cleanForm']}
						</button>
					</div>
				</div>
			</div>
		);
	}

	private readonly onClearRepresentative = (): void => {
		let representative = { ...this.state.representative };
		representative.personId = null;
		representative.personName = null;
		this.props.setRepresentative(representative);
	};

	private readonly onClearCompany = (): void => {
		let representative = { ...this.state.representative };
		representative.legalCompanyId = null;
		representative.legalCompanyName = null;
		this.props.setRepresentative(representative);
	};	

	private readonly handleIsPersonChange = (event, value: any) => {
		let isPhysical = value === 'true';
		let resetRepresentative = createEmptyPowerRepresentativeDetail();
		this.setState({ representative: resetRepresentative, selectedPerson: null , selectedCompany:null, isPhysical});
	};
	private readonly cleanRepresentativeDetail = () => {
		let resetRepresentative = createEmptyPowerRepresentativeDetail();
		this.setState({ representative: resetRepresentative, selectedPerson: null , selectedCompany:null,});
	};

	private readonly handleAddClick = () => {
		let representative = { ...this.state.representative };
		representative.powerTypeId = this.props.powerTypeId;
		if (this.state.representative.state === RepresentativeState.Revocación) {
			representative.revokeOn = this.props.documentDate;
		}
		if (this.validateForm(representative)) {
			const trazas = `Se ha añadido un apoderado con los siguientes valores: ${this.state.trazas}`
			representative.trazas = trazas;
			this.props.addRepresentative(representative);
			this.setState({ errorMessages: [] });
		}
	};

	private readonly handleUpdateClick = () => {
		let representative = { ...this.state.representative };
		representative.powerTypeId = this.props.powerTypeId;
		if(!this.state.isPhysical ){
		}
		if (this.state.representative.state === RepresentativeState.Revocación) {
			representative.revokeOn = this.props.documentDate;
		}
		if (this.validateForm(representative)) {
			const trazas = `Se ha editado el Apoderado con id ${representative.id} con los siguientes cambios:\n ${this.state.trazas}`
			representative.trazas = trazas;
			this.props.updateRepresentative(representative, trazas);
			this.props.setFinishedEdit(true);
			this.setState({ errorMessages: [] });
		}
	};

	private validateForm(representative: PowerRepresentativeDetail): boolean {
		let errorMessages: string[] = [];
		let language = utils.getResourcesFile(this.props.language);

		let existPerson = representative.personId > 0 || representative.legalCompanyId > 0;
		let hasPowerType = representative.powerTypeId > 0;
		let hasState = representative.state > 0;
		let hasPowerRevoke = representative.powerToRevokeId > 0 && representative.state === RepresentativeState.Revocación;
		let isRepresentativeValid =
			existPerson && hasPowerType && hasState && (representative.state === RepresentativeState.Otorgamiento || hasPowerRevoke);
		if (!existPerson) {
			errorMessages.push(language['generic']['messages']['required-person']);
		}
		if (!hasPowerType) {
			errorMessages.push(language['generic']['messages']['required-powerType']);
		}
		if (!hasState) {
			errorMessages.push(language['generic']['messages']['required-grantRevoke']);
		}
		if (representative.state === RepresentativeState.Revocación && !hasPowerRevoke) {
			errorMessages.push(language['generic']['messages']['required-powerToRemove']);
		}

		if (!isRepresentativeValid) {
			this.setState({ errorMessages });
		}
		return isRepresentativeValid;
	}

	private readonly handlePersonChange = (selected): void => {
		let representative = { ...this.state.representative };
		representative.personId = parseInt(selected?.value);
		representative.personName = selected?.label;
		let trazas: string[] = [...this.state.trazas]
		//const cambioId = `IdApoderado: ${representative.personId}\n`;
		const cambioNombre = `NombreApoderado: ${representative.personName}\n`;
		trazas.push(cambioNombre);
		this.getPowersToRevoke(representative.personId, representative.legalCompanyId);
		this.setState({ representative: representative, selectedPerson: selected, trazas: trazas });
		this.props.setRepresentative(representative);
	};

	private readonly handleCompanyChange = (selected): void => {
		let representative = { ...this.state.representative };
		representative.legalCompanyId = parseInt(selected?.value);
		representative.legalCompanyName = selected?.label;
		let trazas: string[] = [...this.state.trazas]
		//const cambioId = `CompanyId: ${representative.legalCompanyId}\n`;
		const cambioNombre = `Compañia: ${representative.legalCompanyName}\n`;
		trazas.push(cambioNombre);
		this.getPowersToRevoke(representative.personId, representative.legalCompanyId);
		this.setState({ representative: representative, selectedCompany: selected, trazas: trazas });
		this.props.setRepresentative(representative);	
	};

	private readonly handleExerciseTypeChange = (event, value): void => {
		let representative = { ...this.state.representative };
		representative.powerExcerciseId = value.key >0 ? value.key : null;
		let trazas: string[] = [...this.state.trazas]
		let cambioId,cambioNombre:string;

		switch(representative.powerExcerciseId){
			case 1:
				cambioId = `powerExerciseId: ${representative.powerExcerciseId}`
				cambioNombre = `Forma de Ejercicio: Solidaria\n`
				break;
			case 2:
				cambioId = `powerExerciseId: ${representative.powerExcerciseId}`
				cambioNombre = `Forma de Ejercicio: Mancomunidad\n`
			case 3:
				cambioId = `powerExerciseId: ${representative.powerExcerciseId}`
				cambioNombre = `Forma de Ejecericio: Ambas\n`
		}

		trazas.push(cambioNombre);
		this.setState({ representative:representative, trazas:trazas });
		this.props.setRepresentative(representative);
	};

	private readonly handleGrantRevokeChange = (event, value): void => {
		let representative = { ...this.state.representative };
		representative.state = value?.key;
		if (representative.state !== RepresentativeState.Revocación) {
			representative.powerToRevokeId = null;
		}
		let trazas: string[] = [...this.state.trazas]
		let cambioId,cambioNombre:string;
		switch(representative.state){
			case 1: 
				cambioId = `idState: ${representative.state}`
				cambioNombre = `NombreEstado: Revocacion\n`
				break;
			case 2: 
				cambioId = `idState: ${representative.state}`
				cambioNombre = `NombreEstado: Otorgamiento\n`
				break;
		}

		trazas.push(cambioNombre)
		
		this.setState({ representative: representative, trazas: trazas });
		this.props.setRepresentative(representative);
	};

	private readonly handleDepartmentChange = (event, value): void => {
		let representative = { ...this.state.representative };
		representative.departmentId = value?.key;
		let trazas: string[] = [...this.state.trazas]
		const cambioId = `Departamento: ${value.text}\n`;
		trazas.push(cambioId);
		this.setState({ representative: representative, trazas:trazas });
		this.props.setRepresentative(representative);
	};

	private readonly handlePowerToRevokeChange = (event, value): void => {
		let representative = { ...this.state.representative };
		representative.powerToRevokeId = value?.key;
		let trazas: string[] = [...this.state.trazas]
		const cambioId = `IdPoderRevocado: ${representative.legalCompanyId}\n`;
		trazas.push(cambioId);
		this.setState({ representative: representative, trazas:trazas });
		this.props.setRepresentative(representative);
	};

	public readonly handleDocumentDateChange = (event): void => {
		let representative = { ...this.state.representative };
		representative.revokeOn = event;
		let trazas: string[] = [...this.state.trazas]
		const documentDate = `FechaDocumento: ${utils.formatDate(representative.revokeOn)}\n`;
		trazas.push(documentDate);
		this.setState({ representative: representative, trazas:trazas });
		this.props.setRepresentative(representative);
	};

	private readonly onClearDate = (): void => {
		this.handleDocumentDateChange(null);
    };	

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filter(inputValue));
		}, 1000);
	};

	private readonly filter = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.people && this.props.people.length > 0) {
			this.props.people.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};
	private readonly loadOptionsCompanies = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterCompanies(inputValue));
		}, 1000);
	};

	private readonly filterCompanies = (inputValue: string) => {
		let companies: SearchSelectModel[] = [];
		if (this.props.companiesSearch && this.props.companiesSearch.length > 0) {
			this.props.companiesSearch.forEach((item) => {
				companies.push({ value: item.id, label: `${item.name}` });
			});
		}
		return companies.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
	};

	private readonly getPowersToRevoke = (personId: number,legalCompanyId :number) => {
		let powerRevocationFilter = this.props.powerRevocationFilter;
		powerRevocationFilter.personId = personId;
		powerRevocationFilter.legalCompanyId = legalCompanyId;
		powerRevocationFilter.statePower = this.props.representative===null?0:this.props.representative.state;
		powerRevocationFilter.isEdit = this.props.isEdit;
		this.props.getPowerSubjects(powerRevocationFilter);
	};
}
