import { GenericApi, SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { Language, UserInfo } from '../../../../model';
import { BodyTypesFilter } from '../../../../model/filters/BodyTypesFilter';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
import { getBodyTypesAction } from './getBodyTypes';

export const deleteBodyTypeAction = (filter: BodyTypesFilter, currentLanguage: Language, id: number, itemsPage: number, currentUser: UserInfo) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.BodyTypes, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['body-types']['form']['messages']['success-delete']);
			const trazaBodyType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Delete Body Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha eliminado el tipo de órgano: ${id}`,
                IdRegistro: id.toString(),
            }
            SendTraceToBBDD(trazaBodyType)
		} else {
			showError(language['body-types']['form']['messages']['error-delete']);
		}

		dispatch(getBodyTypesAction(filter, 1, itemsPage));
		dispatch(deleteBodyTypeCompleted(result));
	});
};

const deleteBodyTypeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_BODY_TYPE,
	payload: result,
});
