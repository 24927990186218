import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../../common/const/resources';
import { onlyDeedsModule } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { BaseType, NotaryPeople } from '../../../model';
import { BaseName } from '../../../model/Base';
import { DeedCollection } from '../../../model/Deed';
import { DeedType, DeedTypeSubType } from '../../../model/DeedType';
import { Language } from '../../../model/Language';
import { User } from '../../../model/User';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { DeedFilter } from '../../../model/filters/DeedFilter';
import { UserState } from '../../../reducers/users';
import * as utils from '../../../utils/extensions';
import DeedsDataGrid from './grid';
import { DeedsSearchComponent } from './search';

export interface IProps {
	deedCollection: DeedCollection;
	companies: BaseName[];
	deedTypeSubTypes: DeedTypeSubType[];
	deedTypePrimaries: DeedType[];
	user: User;
	language: Language;
	currentUser: UserState;
	filter: DeedFilter;
	business: BaseType[];
	originals: BaseType[];

	setDeedFilter: (filter: DeedFilter) => DeedFilter;
	deleteDeed: (filter: DeedFilter, language: Language, id: number, currentUser: UserState) => Promise<boolean>;
	getDeeds: (filter: DeedFilter, pageNumber: number, itemsPage: number) => Promise<DeedCollection>;
	getCompanies: () => Promise<BaseName[]>;
	getDeedTypesSubTypes: () => Promise<DeedTypeSubType[]>;
	getDeedTypePrimaries: () => Promise<DeedType[]>;
	exportToExcel: (filter: DeedFilter) => Promise<any>;
	getBusiness: () => Promise<BaseType[]>;
	getOriginals: () => Promise<BaseType[]>;
	getNotaryPeopleNames: (filter: string) => Promise<NotaryPeople[]>;
}

export interface IState {
	orderByCompany: string;
	orderByDeedType: string;
	orderByDeedSubtype: string;
	orderByDocumentDate: string;
	orderByNotary: string;
	orderByInscription: string;
	orderByInscriptionDate: string;
	isAuthorized: boolean;
	isLoading: boolean;
	filter: DeedFilter;
}

export class DeedsPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			orderByCompany: 'ms-Icon--ChevronUpSmall',
			orderByDeedType: 'ms-Icon--ChevronUpSmall',
			orderByDeedSubtype: 'ms-Icon--ChevronUpSmall',
			orderByDocumentDate: 'ms-Icon--ChevronUpSmall',
			orderByNotary: 'ms-Icon--ChevronUpSmall',
			orderByInscription: 'ms-Icon--ChevronUpSmall',
			orderByInscriptionDate: 'ms-Icon--ChevronUpSmall',
			filter: this.props.filter,
			isAuthorized: false,
			isLoading: true,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyDeedsModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (this.props.location.state != null) {
			this.props.getDeeds(this.props.location.state.deedFilter, page, this.props.filter.ItemsPage);
		} else {
			this.props.getDeeds(this.props.filter, page, this.props.filter.ItemsPage);
		}
		this.props.getCompanies();
		this.props.getDeedTypeSubTypes();
		this.props.getDeedTypePrimaries();
		this.props.getBusiness();
		this.props.getOriginals();
		this.props.getNotaryPeopleNames();
	}

	public componentDidUpdate(prevProps) {
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getDeeds(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
		if (this.props.currentUser !== prevProps.currentUser) {
			let isAuth = utils.isInRoles(onlyDeedsModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let deeds = this.props.deedCollection && this.props.deedCollection.list?.length > 0 ? this.props.deedCollection.list.slice() : [];

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}


		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['deeds']['list']['title']}</h1>
							{
								<Link to='/deeds/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['deeds']['list']['new-button']}</span>
								</Link>
							}
						</div>
						<div className='ms-Grid'>
							<DeedsSearchComponent
								filter={this.state.filter}
								getDeeds={this.props.getDeeds}
								companies={this.props.companies}
								getCompanies={this.props.getTradeCompanies}
								tradeCompanies={this.props.tradeCompanies}								
								deedTypeSubTypes={this.props.deedTypeSubTypes}
								deedTypePrimaries={this.props.deedTypePrimaries}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								itemsPage={this.state.filter.ItemsPage}
								business={this.props.business}
								originals={this.props.originals}
								notaryPeopleNames={this.props.notaryPeopleNames}
								getNotaryPeopleNames={this.props.getNotaryPeopleNames}
								user={this.props.currentUser}
							/>
							{this.props.deedCollection.totalNumberOfRows > 0 ? (
								<DeedsDataGrid
									deeds={deeds}
									deleteDeed={this.props.deleteDeed}
									user={this.props.user}
									language={this.props.language}
									setDeedFilter={this.props.setDeedFilter}
									filter={this.state.filter}
									page={page}
									deedCollection={this.props.deedCollection}
									onchangeFilter={this.handleFilterChange}
									exportToExcel={this.props.exportToExcel}
									getDeeds={this.props.getDeeds}
									currentUser={this.props.currentUser}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['deeds']['list']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}
	public readonly handleFilterChange = (newfilter: DeedFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.deedsPagination + 1);
	};
}
