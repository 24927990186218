import * as fetch from 'isomorphic-fetch';

import * as utils from '../../utils/extensions';
import { OrderBy } from '../../model/filters/OrderBy';
import { BaseTypeCollection } from '../../model/BaseType';
import { Constants } from '../../common/const/constants';
import { BaseTypeFilter } from '../../model/filters/BaseTypeFilter';
import moment from 'moment';
import { User } from '../../model/User';
import { runtimeVariables } from '../../globalEnvVariables';

async function FilterAsync(baseUrl: string, filter: BaseTypeFilter, pageNumber: number): Promise<BaseTypeCollection> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/GetByFilter`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?HasOrder=true&NumRegister=${Constants.ItemsPerPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	queryString += '&DescriptionSpanishOrder=';
	if (filter.DescriptionSpanishOrder === OrderBy.Asc) {
		queryString += 'Asc';
	} else {
		queryString += 'Desc';
	}

	if (!utils.isNullOrEmpty(filter.DescriptionSpanish)) {
		queryString += '&DescriptionSpanish=' + encodeURIComponent(filter.DescriptionSpanish) + '';
	}
	if (!utils.isNullOrEmpty(filter.DescriptionEnglish)) {
		queryString += '&DescriptionEnglish=' + encodeURIComponent(filter.DescriptionEnglish) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
}

async function GetAsync<T>(baseUrl: string): Promise<T[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/Get`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let randomGuid: string = utils.generateRandomGuid(true);

	return fetch(baseURL + randomGuid, obj).then((response) => response.json());
}

async function GetByIdAsync<T>(baseUrl: string, id: number): Promise<T> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/GetById`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
			Pragma: 'no-cache',
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response) => response.json());
}

async function AddAsync<T>(baseUrl: string, item: T): Promise<number> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/Add`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	Date.prototype.toJSON = function () {
		return moment(this).format();
	};
	
	let obj: RequestInit = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};
	return fetch(baseURL, obj).then((response) => response.json());
}

async function UpdateAsync<T>(baseUrl: string, item: T): Promise<number> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/Update`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();
	
	Date.prototype.toJSON = function () {
		return moment(this).format();
	};

	let obj: RequestInit = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};

	return fetch(baseURL, obj).then((response) => response.json());
}

async function DeleteAsync(baseUrl: string, id: number): Promise<boolean> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/Delete`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response) => response.json());
}

//TODO: Hacer la llamada al endpoint de fernando y ver que parametros hay que pasarle
export async function SendTraceToBBDD<T>(traza: T):Promise<boolean>{

	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/SoxTraces/Add`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();
	
	let obj: RequestInit = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(traza),
	};
	return fetch(baseURL, obj).then((response) => response.json());
}

export const GenericApi = {
	GetAsync,
	GetByIdAsync,
	FilterAsync,
	AddAsync,
	UpdateAsync,
	DeleteAsync,
	SendTraceToBBDD
};
