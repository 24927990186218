import { GenericApi, SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { Language, Person, UserInfo, UserState } from '../../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../../utils/extensions';
const browserHistory = useHistory;


export const savePersonAction = (person: Person, currentLanguage: Language, currentUser: UserInfo, previousPerson?: Person) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    dispatch(httpCallStartAction());
    if (person.id > 0) {
        handleUpdatePerson(person, language, currentUser, previousPerson, dispatch);
    } else {
        handleCreatePerson(person, language,currentUser, dispatch);
    }
};

const handleUpdatePerson = (person: Person, language: any, currentUser: UserInfo, dispatch, previousPerson?: Person) => {
    GenericApi.UpdateAsync(ApiUrls.People, person).then((result: any) => {
        if (result.error) {
            showError(language['people']['form']['messages']['error-update'] + language['generic']['error'][result.error]);
            dispatch(saveCategoryCompleted(result));
        } else {
            showSuccess(language['people']['form']['messages']['success-update']);
            browserHistory.goBack();
            const trazaPerson = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Person",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado la persona ${person.id}, con los siguientes cambios: ${objectToString(compareObjects(previousPerson, person))}`,
                IdRegistro: person.id.toString()
            }
            SendTraceToBBDD(trazaPerson)
            dispatch(saveCategoryCompleted(result));
        }
    });
}

const handleCreatePerson = (person: Person, language: any,currentUser: UserInfo, dispatch) => {
    GenericApi.AddAsync(ApiUrls.People, person).then((result: any) => {
        if (result > 0) {
            showSuccess(language['people']['form']['messages']['success']);
            browserHistory.goBack();           
            person.id = result;
            const trazaPerson = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Create Person",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado la persona con id:  ${person.id}`,
                IdRegistro: person.id.toString()
            }
            SendTraceToBBDD(trazaPerson)
            dispatch(saveCategoryCompleted(result));

        } else {
            showError(language['people']['form']['messages']['error'] + language['generic']['error'][result.error]);
            dispatch(saveCategoryCompleted(result));
        }
    });
}

const saveCategoryCompleted = (result: number) => ({
    type: actionTypes.SAVE_PERSON_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});