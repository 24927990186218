import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';

import { DocumentType, DocumentTypeCollection, DocumentTypeSubType } from '../../model/DocumentType';
import { ActiveDocumentTypeOrderFilter } from '../../model/filters/ActiveDocumentTypeOrderFilter';
import { DocumentTypeFilter } from '../../model/filters/DocumentTypeFilter';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';
import { runtimeVariables } from '../../globalEnvVariables';

async function FilterAsync(filter: DocumentTypeFilter, pageNumber: number, itemsPage: number): Promise<DocumentTypeCollection> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DocumentTypes}/GetByFilter`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveDocumentTypeOrderFilter.DescriptionSpanish:
			queryString += '&DescriptionSpanishOrder=';
			if (filter.DescriptionSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveDocumentTypeOrderFilter.DescriptionEnglish:
			queryString += '&DescriptionEnglishOrder=';
			if (filter.DescriptionEnglishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveDocumentTypeOrderFilter.FatherId:
			queryString += '&FatherIdOrder=';
			if (filter.TypeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&DescriptionSpanishOrder=';
			if (filter.DescriptionSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}
	if (!utils.isNullOrEmpty(filter.DescriptionSpanish)) {
		queryString += '&DescriptionSpanish=' + encodeURIComponent(filter.DescriptionSpanish) + '';
	}
	if (!utils.isNullOrEmpty(filter.DescriptionEnglish)) {
		queryString += '&DescriptionEnglish=' + encodeURIComponent(filter.DescriptionEnglish) + '';
	}
	if (filter.FatherId > 0) {
		queryString += '&FatherId=' + encodeURIComponent(filter.FatherId) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
}

async function ExportToExcel(filter: DocumentTypeFilter): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DocumentTypes}/ExportToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('document-types')),
	);
}

function ExportToExcelQuery(filter: DocumentTypeFilter): string {
	let queryString: string = '';

	if (filter.FatherId != null) {
		queryString += '&FatherId=' + filter.FatherId;
	}

	if (filter.DescriptionSpanish != null) {
		queryString += '&DescriptionSpanish=' + filter.DescriptionSpanish;
	}

	if (filter.DescriptionEnglish != null) {
		queryString += '&DescriptionEnglish=' + filter.DescriptionEnglish;
	}

	return queryString;
}

async function GetDocumentTypePrimaries(): Promise<DocumentType[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DocumentTypes}/GetListDocumentTypePrimary`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + randomGuid, obj).then((response) => response.json());
}

async function GetDocumentTypeSubType(): Promise<DocumentTypeSubType[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DocumentTypes}/GetListDocumentTypeSubType`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + randomGuid, obj).then((response) => response.json());
}
export const DocumentTypeApi = {
	FilterAsync,
	ExportToExcel,
	GetDocumentTypePrimaries,
	GetDocumentTypeSubType,
};
