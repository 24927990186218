import { showSuccess, showError } from '../../../../utils/extensions';
import { actionTypes } from '../../../../common/const/actionTypes';
import { RepresentativeApi } from '../../../../api/representatives';
import { RepresentativeFilter } from '../../../../model/filters/RepresentativeFilter';
import { getRepresentativesAction } from './getRepresentatives';
import { Language, UserState } from '../../../../model';
import { getResourcesFile } from '../../../../utils/extensions';
import { SendTraceToBBDD } from '../../../../api/generic';

export const deleteRepresentativeAction = (filter: RepresentativeFilter, currentLanguage: Language, id: number, currentUser: UserState) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	RepresentativeApi.DeleteAsync(id).then((result) => {
		if (result) {
			showSuccess(language['representatives']['form']['messages']['success-delete']);
		} else if (result === false) {
			showError(language['representatives']['form']['messages']['error-delete']);
		} else {
			showError(language['representatives']['form']['messages']['error-delete']);
		}

		const trazaLogin = {
			Usuario: currentUser.currentUser.email,
			Grupos: currentUser.currentUser.roles.join(','),
			Accion: "Delete Power",
			Fecha: new Date().toDateString(),
			Traza: `Se ha borrado el poder ${id}`,
			IdRegistro: id.toString()
		}
		dispatch(SendTraceToBBDD(trazaLogin));

		dispatch(getRepresentativesAction(filter, 1, 10));
		dispatch(deleteRepresentativeCompleted(result));
	});
};
const deleteRepresentativeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_REPRESENTATIVE,
	payload: result,
});
