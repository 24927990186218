import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { Constants } from '../../../common/const/constants';
import { Language, UserInfo } from '../../../model';
import { DocumentTypeFilter } from '../../../model/filters';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
import { getDocumentTypeCollectionAction } from './getDocumentTypeCollection';

export const deleteDocumentTypeAction = (filter: DocumentTypeFilter, currentLanguage: Language, id: number, currentUser: UserInfo) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.DocumentTypes, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['documentType']['messages']['success-delete']);
			const trazaDocumentType = {
				Usuario: currentUser.mail,
				Grupos: currentUser.roles.join(','),
				Accion: "Update Document Type",
				Fecha: new Date().toDateString(),
				Traza: `Se ha eliminado el DocumentType:  ${id}`,
				IdRegistro: id.toString(), 
			}
			SendTraceToBBDD(trazaDocumentType)
		} else {
			showError(language['documentType']['messages']['error-delete'] + " " + language['generic']['error'][result.error]);
		}

		dispatch(getDocumentTypeCollectionAction(filter, 1, Constants.ItemsPerPage));
		dispatch(deleteDocumentTypeCompleted(result));
	});
};
const deleteDocumentTypeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_DOCUMENT_TYPE,
	payload: result,
});
