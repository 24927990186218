import * as React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { User, Language, BodyTypes, BaseType, createBodyType, UserInfo } from '../../../model';
import { languages } from '../../../common/const/resources';
import { UserRoles } from '../../../model/enums/userRoles';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/components/ComboBox';
import * as utils from '../../../utils/extensions';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { MessageBarType } from 'office-ui-fabric-react/lib/components/MessageBar';
import { MeApi } from '../../../api/me';

export interface IProps {
	bodyType: BodyTypes;
	user: User;
	language: Language;
	business: BaseType[];
	loading: boolean;
	saveBodyType: (bodyType: BodyTypes, language: Language, currentUser: UserInfo, previousBodyType: BodyTypes) => void;
	getBodyType: (id: number) => Promise<BodyTypes>;
	getBusiness: () => Promise<BaseType[]>;
}

export interface IState {
	bodyType: BodyTypes;
	errorMessages: string[];
}

export class BodyTypesForm extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props: any) {
		super(props);

		this.state = {
			bodyType: createBodyType(),
			errorMessages: []
		};
	}

	public componentDidMount() {
		if (this.props.match.params['id'] != null) {
			this.props.getBodyType(this.props.match.params['id']);
		}
	}

	public componentWillReceiveProps(props) {
		if (props.bodyType !== this.props.bodyType) {
			this.setState({ bodyType: { ...props.bodyType } });
		}
	}

	public render() {
		let lector: boolean = false;
		if (this.props.user.rol === UserRoles.reader) {
			lector = true;
		}

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return (
				<MessageBarComponent
					Message={item}
					Type={MessageBarType.error}
					MessageVisible={true}
					close={null} />);
		});

		let businessCombo: IComboBoxOption[] = [];
		this.props.business.forEach((item) => {
			businessCombo.push({
				key: item.id,
				text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
			});
		});

		return (
			<>
				<div className='main-container__pageTitle'>
					<h1>
						{this.props.match.params['id'] == null
							? language['body-types']['form']['text-add']
							: language['body-types']['form']['text-edit']}
						{language['body-types']['form']['title']}
					</h1>
				</div>
				<div className='ms-Grid'>
					<div className='ms-Grid-row flex-option'>
						<div className='ms-Grid-col ms-xl3 main-container__content_edit'>
							<p>{language['body-types']['form']['fields']['description-es']}</p>
							<TextField
								type='text'
								value={this.state.bodyType.descriptionSpanish}
								onChange={this.handleDescriptionSpanishChange}
								onBlur={this.handleDescriptionSpanishBlur}
								maxLength={100}
							/>
							<p>{language['body-types']['form']['fields']['description-en']}</p>
							<TextField
								type='text'
								value={this.state.bodyType.descriptionEnglish}
								onChange={this.handleDescriptionEnglishChange}
								onBlur={this.handleDescriptionEnglishBlur}
								maxLength={100}
							/>
						</div>
					</div>
					<div className='ms-Grid-row'>
						<hr />
						{validationErrors}
						{!lector ? (
							<button
								type='button'
								name='button'
								className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
								onClick={this.handleSaveBodyType}
							>
								{language['body-types']['form']['save-button']}
							</button>
						) : (
							<span></span>
						)}
						<button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
							{language['body-types']['form']['cancel-button']}
						</button>
					</div>
				</div>
			</>
		);
	}

	private readonly handleSaveBodyType = (): number => {
		let bodyType = { ...this.state.bodyType };
		if (this.validateBodyType(bodyType)) {
			MeApi.GetUserInfo().then((user)=>{
				return this.props.saveBodyType(bodyType, this.props.language, user, this.props.bodyType);
			})
		}
		return 0;
	};

	private readonly handleDescriptionSpanishChange = (value): void => {
		let bodyType = { ...this.state.bodyType };
		bodyType.descriptionSpanish = value.target.value;
		this.setState({ bodyType: bodyType });
	};
	private readonly handleDescriptionSpanishBlur = (value): void => {
		let bodyType = { ...this.state.bodyType };
		bodyType.descriptionSpanish = value.target.value.toUpperCase();
		this.setState({ bodyType: bodyType });
	};

	private readonly handleDescriptionEnglishChange = (value): void => {
		let bodyType = { ...this.state.bodyType };
		bodyType.descriptionEnglish = value.target.value;
		this.setState({ bodyType: bodyType });
	};
	private readonly handleDescriptionEnglishBlur = (value): void => {
		let bodyType = { ...this.state.bodyType };
		bodyType.descriptionEnglish = value.target.value.toUpperCase();
		this.setState({ bodyType: bodyType });
	};

	private readonly validateBodyType = (bodyType: BodyTypes) => {
		let errorMessages: string[] = [];
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let hasDescriptionSpanish = !utils.isNullOrEmpty(bodyType.descriptionSpanish);
		let hasDescriptionEnglish = !utils.isNullOrEmpty(bodyType.descriptionEnglish);

		let isBodyTypeValid = hasDescriptionSpanish && hasDescriptionEnglish;
		if (!hasDescriptionSpanish) {
			errorMessages.push(language['generic']['messages']['required-spanish-description']);
		}
		if (!hasDescriptionEnglish) {
			errorMessages.push(language['generic']['messages']['required-english-description']);
		}

		if (!isBodyTypeValid) {
			this.setState({ errorMessages });
		}

		return isBodyTypeValid;
	}
}
