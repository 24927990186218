

import { initializeIcons } from '@uifabric/icons';
import 'office-ui-fabric-react/dist/css/fabric.css';
import React from 'react';
// import { msalInstance } from './common/security/msal/msal';
import { AzureAD } from 'react-aad-msal';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SpinnerContainer } from './common/spinner';
import * as RoutesModule from './routes';
import TelemetryProvider from './services/telemetryProvider';
import { getAppInsights } from './services/telemetryService';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import './styles/components.scss';
import './styles/main.scss';
import './styles/vendors/toastr.min.css';

import { AppInsights } from "applicationinsights-js";
import { runtimeVariables } from './globalEnvVariables';

runtimeVariables.instance(window.location.href);

const routes = RoutesModule.routes;
//let appInsights = null;  		//Clean Warning Code 



declare global {
	/* eslint-disable-next-line */
	namespace JSX {
		interface IntrinsicElements {
			'mgt-flyout': any;
			'mgt-login': any;
			'mgt-person': any;
			'mgt-msal-provider': any;
			'mgt-tasks': any;
			'mgt-people-picker': any;
			'mgt-person-card': any;
			person: any;
		}
	}
}
// Providers.globalProvider = new SimpleProvider(async (scopes) => {
//   const request: AuthenticationParameters = {  };
//     request.scopes = scopes;
//     console.log(scopes);
// 		return msalInstance.acquireTokenSilent(request).then((response) => {
// 			return response.accessToken;
// 		});

// });
// Providers.globalProvider.setState(ProviderState.Loading);

//checkEnviroment(window.location.href);

function renderApp() {


	//checkEnviroment(window.location.href);

	const instrumentationKey = runtimeVariables._instance.REACT_APP_INSTRUMENTATION_KEY;
	console.log(runtimeVariables._instance.REACT_APP_REDIRECT_URI);

    try{
		AppInsights.downloadAndSetup({ instrumentationKey: instrumentationKey });
	} catch (e){
		console.log("Error AppInsights");
	}

	initializeIcons(undefined, { disableWarnings: true });
	const message = 'Cargando...';
	ReactDOM.render(
		<AppContainer>
			<Provider store={store}>
				<SpinnerContainer message={message} />
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<TelemetryProvider
						instrumentationKey={instrumentationKey}
						after={() => {
							//appInsights = getAppInsights();	//Clean Warning Code 
							return getAppInsights();
						}} 
					>
						<AzureAD provider={runtimeVariables._instance.authProvider} forceLogin={true} reduxStore={store}>
							{routes}
						</AzureAD>
					</TelemetryProvider>
				</BrowserRouter>
			</Provider>
		</AppContainer>,
		document.getElementById('root'),
	);
}

renderApp();

serviceWorker.unregister();
