import { Person } from '../../model/Person';

import * as utils from '../../utils/extensions';
import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { PersonFilter } from '../../model/filters/PersonFilter';
import { ActivePersonOrderFilter } from '../../model/filters/ActivePersonOrderFilter';
import { OrderBy } from '../../model/filters/OrderBy';
import { runtimeVariables } from '../../globalEnvVariables';

// let pageRender: number = 0;		Clean Warning Code 

const FilterAsync = async (filter: PersonFilter, pageNumber: number, itemsPage: number): Promise<Person[]> => {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.People}/Get`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	// pageRender = pageNumber;		Clean Warning Code 
	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActivePersonOrderFilter.Name:
			queryString += '&NameOrder=';
			if (filter.NameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePersonOrderFilter.Dni:
			queryString += '&DniOrder=';
			if (filter.DniOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActivePersonOrderFilter.Email:
			queryString += '&EmailOrder=';
			if (filter.EmailOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePersonOrderFilter.Passport:
			queryString += '&PassportOrder=';
			if (filter.PassportOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePersonOrderFilter.TradeSpanish:
			queryString += '&TradeOrderSpanish=';
			if (filter.TradeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePersonOrderFilter.TradeEnglish:
			queryString += '&TradeOrderEnglish=';
			if (filter.TradeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePersonOrderFilter.Nacionality:
			queryString += '&NationalityOrder=';
			if (filter.NacionalityOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&NameOrder=';
			if (filter.NameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}

	if (filter.PersonId != null) {
		queryString += '&PersonId=' + filter.PersonId;
	}

	// if (!utils.isNullOrEmpty(filter.Name)) {
	// 	queryString += '&Name=' + encodeURIComponent(filter.Name) + '';
	// }
	if (!utils.isNullOrEmpty(filter.DNI)) {
		queryString += '&DNI=' + encodeURIComponent(filter.DNI) + '';
	}

	if (!utils.isNullOrEmpty(filter.Email)) {
		queryString += '&Email=' + encodeURIComponent(filter.Email) + '';
	}

	if (filter.TradeId != null) {
		queryString += '&TradeId=' + filter.TradeId;
	}

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
};

async function GetPeopleNamesAsync(): Promise<Person[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.People}/GetPeople`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	return fetch(baseURL, obj).then((response) => response.json());
}

async function FindInAzureAD(email: string): Promise<string> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.People}/SearchByEmail?email=${email}`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	return fetch(baseURL, obj).then((response: any) => response.json());
}

async function ExportToExcel(filter: PersonFilter): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.People}/ExportToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = '';

	if (filter.PersonId != null) {
		queryString += '&PersonId=' + filter.PersonId;
	}

	if (!utils.isNullOrEmpty(filter.DNI)) {
		queryString += '&DNI=' + encodeURIComponent(filter.DNI) + '';
	}

	if (!utils.isNullOrEmpty(filter.Email)) {
		queryString += '&Email=' + encodeURIComponent(filter.Email) + '';
	}

	if (!utils.isNullOrEmpty(filter.Email)) {
		queryString += '&Email=' + encodeURIComponent(filter.Email) + '';
	}
	if (filter.TradeId != null) {
		queryString += '&TradeId=' + filter.TradeId;
	}

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => utils.downloadBlob(response, utils.getExcelNames('people')));
}

async function GetPeopleNamesFilteredAsync(filter: string): Promise<Person[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.People}/GetPeopleFiltered?filter=${filter}`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	return fetch(baseURL, obj).then((response) => response.json());
}
export const PeopleApi = {
	FilterAsync,
	FindInAzureAD,
	GetPeopleNamesAsync,
	ExportToExcel,
	GetPeopleNamesFilteredAsync,
};
