import { UserState } from './../../../../model/User';
import { DeedApi } from '../../../../api/deed';
import { SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { Deed, Language } from '../../../../model';
import { compareObjects, getChangedFields, getResourcesFile, limpiarObjeto, objectToString, showError, showSuccess } from '../../../../utils/extensions';
import { isArray } from '@pnp/common';
const browserHistory = useHistory;

export const saveDeedAction = (deed: Deed, currentLanguage: Language, currentUser:UserState , objetoTrazas?: string) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (deed.id > 0) {
        handleUpdateDeed(deed, language, dispatch, currentUser, objetoTrazas);
    } else {
        handleCreateDeed(deed, language, dispatch, currentUser, objetoTrazas);
    }
};

const handleUpdateDeed = (deed: Deed, language: any, dispatch, currentUser:UserState , objetoTrazas?: string) => {
    DeedApi.UpdateAsync(ApiUrls.Deed, deed).then((result: any) => {
        if (result.error) {
            showError(language['deeds']['form']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDeedCompleted(result));
        }
        else {
            showSuccess(language['deeds']['form']['messages']['success-update']);
            browserHistory.goBack();
            dispatch(saveDeedCompleted(result));
            const trazaDeed = {
                Usuario: currentUser.currentUser.email,
                Grupos: currentUser.currentUser.roles.join(','),
                Accion: "Update Deed",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado la escritura ${deed.id}, con los siguientes cambios: ${objetoTrazas}`,
                IdRegistro: deed.id.toString()
            }
            SendTraceToBBDD(trazaDeed)
            
        }
    });
}

const handleCreateDeed = (deed: Deed, language: any, dispatch, currentUser: UserState, objetoTrazas: string) => {
    DeedApi.AddAsync(ApiUrls.Deed, deed).then((result: any) => {
        if (result > 0) {
            showSuccess(language['deeds']['form']['messages']['success']);
            dispatch(saveDeedCompleted(result));
            deed.id = result;
            const trazaDeed = {
                Usuario: currentUser.currentUser.email,
                Grupos: currentUser.currentUser.roles.join(','),
                Accion: "Create Deed",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado la escritura ${deed.id} con los siguientes parametros: ${objetoTrazas}`,
                IdRegistro: deed.id.toString()
            }
            SendTraceToBBDD(trazaDeed)
            browserHistory.goBack();
        } else {
            showError(language['deeds']['form']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDeedCompleted(result));
        }
    });
}

const saveDeedCompleted = (result: number) => ({
    type: actionTypes.SAVE_DEED_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});