import * as React from 'react';
import { DayOfWeek, TextField } from 'office-ui-fabric-react';
import {
	BaseType,
	Language,
	BodyPosition,
	SearchSelectModel,
	Person,
	BaseOptionModel,
	TradeCompany,
	createPerson,
	createEmptyBodyPosition,
} from '../../../../../model';
import { getResourcesFile } from '../../../../../utils/extensions';
import { MessageBarComponent } from '../../../../../common/messageBar/messageBarComponent';
import { MessageBarType, IComboBoxOption, ComboBox, Toggle, DatePicker, IDatePickerStrings } from 'office-ui-fabric-react';
import { SearchPeople } from '../../../../../common/search/searchPeople';
import { ButtonGroupComponent } from '../../../../../common/buttonGroup/buttonGroup';
import * as utils from '../../../../../utils/extensions';
import { ActiveLanguage } from '../../../../../model/enums/activeLanguage';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { languages } from '../../../../../common/const/resources';

interface IProps {
	companies: TradeCompany[];
	positionTypes: BaseType[];
	peopleNames: Person[];
	language: Language;
	position: BodyPosition;
	isReadOnly: boolean;
	addPosition: (position: BodyPosition) => void;
	getPeopleNames: (filter: string) => Promise<Person[]>;
	isEdit: boolean;
	updatePosition: (position: BodyPosition) => void;
	setPosition: (position: BodyPosition) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
	cleanPositionEdit: (isEdit: boolean) => void;
}

interface IState {
	position: BodyPosition;
	errorMessages: string[];
	selectedPerson: any;
	trazas:string []
}

export class AdminBodyForm extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			position: this.props.position != null ? this.props.position : createEmptyBodyPosition(),
			errorMessages: [],
			selectedPerson: null,
			trazas: []
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.position !== prevProps.position) {
			let position = { ...this.state.position };
			let selectedPerson = null;
			if (this.props.position.representativeId > 0) {
				selectedPerson = { value: this.props.position.representativeId, label: this.props.position.representative.name };
			}
			position.id = this.props.position.id;
			position.companyId = this.props.position.companyId;
			position.companyName = this.props.position.companyName;
			position.dateAppointmentFinal = this.props.position.dateAppointmentFinal;
			position.isPerson = this.props.position.isPerson;
			position.personId = this.props.position.personId;
			position.positionTypeId = this.props.position.positionTypeId;
			position.proposedAppoiment = this.props.position.proposedAppoiment;
			position.representative = this.props.position.representative;
			position.representativeId = this.props.position.representativeId;
			position.terminationDate = this.props.position.terminationDate;
			position.appointmentDate = this.props.position.appointmentDate;
			position.undefined = this.props.position.undefined;

			this.setState({ position: position, selectedPerson: selectedPerson });
		}
	}


	public render() {
		let language = getResourcesFile(this.props.language);

		let companies: IComboBoxOption[] = [];
		if (this.props.companies) {
			this.props.companies.forEach((item) => {
				companies.push({ key: item.id, text: item.name });
			});
		}

		let positionTypes: IComboBoxOption[] = [];
		if (this.props.positionTypes) {
			this.props.positionTypes.forEach((item) => {
				positionTypes.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		let options: BaseOptionModel[] = new Array(2);

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			options.push({ label: language['NotarialPersonType']['natural'], value: 'true' });
			options.push({ label: language['NotarialPersonType']['legal'], value: 'false' });
		} else {
			options.push({ label: language['NotarialPersonType']['natural'], value: 'true' });
			options.push({ label: language['NotarialPersonType']['legal'], value: 'false' });	
		}
		
		let calendarStrings: IDatePickerStrings = language['calendar'];

		let validationErrors = [];
		this.state.errorMessages.forEach((item) => {
			validationErrors.push(<MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />);
		});
		let selectedPerson = { value: this.state.position?.representativeId, label: this.state.position?.representative?.name };
		return (
			<div className='positionForm'>
				<div className='ms-Grid-row positionForm_inputs positionForm_inputs_border'>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['physical']['title']}</p>
							<ButtonGroupComponent
								onChange={this.handleIsPersonChange}
								options={options}
								value={this.props.position.isPerson.toString()}
								className={'custom-toggle-buttons'}
								disabled={this.props.isReadOnly}
							/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<SearchPeople
							title={
								this.state.position.isPerson
									? language['deeds']['form']['sections']['administrative-body']['fields']['person']['title'] + '*'
									: language['deeds']['form']['sections']['administrative-body']['fields']['representative']['title'] +
									'*'
							}
							placeholder={
								this.state.position.isPerson
									? language['deeds']['form']['sections']['administrative-body']['fields']['person']['placeholder']
									: language['deeds']['form']['sections']['administrative-body']['fields']['representative'][
									'placeholder'
									]
							}
							language={this.props.language}
							selectedOption={selectedPerson}
							loadOptions={this.loadOptions}
							handleNameChange={this.handlePersonChange}
							getUsers={this.props.getPeopleNames}
							disabled={this.props.isReadOnly}
							onClearValue={this.onClearSearchPeople}
							showDeleteButton={true}
						/>
					</div>
					<div
						className='ms-Grid-col  main-container__content_edit_block'
						style={{ display: this.state.position.isPerson ? 'none' : '' }}
					>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['society']['title'] + '*'}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={companies.sort((a, b) => a.text.localeCompare(b.text))}
							placeholder={language['deeds']['form']['sections']['administrative-body']['fields']['society']['placeholder']}
							onChange={this.handleCompanyChange}
							selectedKey={this.state.position.companyId}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['charge-type']['title'] + '*'}</p>
						<ComboBox
							allowFreeform
							autoComplete='on'
							options={positionTypes.sort((a, b) => a.text.localeCompare(b.text))}
							placeholder={
								language['deeds']['form']['sections']['administrative-body']['fields']['charge-type']['placeholder']
							}
							onChange={this.handlePositionTypeChange}
							selectedKey={this.state.position.positionTypeId}
							useComboBoxAsMenuWidth
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['nomination-date']['title']}</p>
						<div className='datepickerContainer'>
							<DatePicker
								formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
								className={''}
								firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
								allowTextInput={true}
								parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
								placeholder={language['generic']['date']}
								strings={calendarStrings}
								value={utils.getParsedDate(this.state.position.appointmentDate)}
								onSelectDate={this.handleAppointmentDateChange}
								disabled={this.props.isReadOnly}
							/>
							<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.onClearAppointmentDate()} />
						</div>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['end-nomination-date']['title']}</p>
						<div className='datepickerContainer'>
							<DatePicker
								formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
								className={''}
								firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
								allowTextInput={true}
								parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
								placeholder={language['generic']['date']}
								strings={calendarStrings}
								value={utils.getParsedDate(this.state.position.dateAppointmentFinal)}
								onSelectDate={this.handleAppoinmentEndDateChange}
								disabled={this.props.isReadOnly}
							/>
							<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.onClearAppointmentEndDate()} />
						</div>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['cessation-date']['title']}</p>
						<div className='datepickerContainer'>
							<DatePicker
								formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
								className={''}
								firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
								allowTextInput={true}
								parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
								placeholder={language['generic']['date']}
								strings={calendarStrings}
								value={utils.getParsedDate(this.state.position.terminationDate)}
								onSelectDate={this.handleTerminationDateChange}
								disabled={this.props.isReadOnly}
							/>
							<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.onClearTerminationDate()} />
						</div>
					</div>
					<div className='ms-Grid-col  main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['indefinite']['title']}</p>
						<Toggle onText={language['BoolValue']['yes']} offText={language['BoolValue']['no']} onChange={this.handleIsUndefinedClick} disabled={this.props.isReadOnly} checked={this.state.position.undefined}/>
					</div>
					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['administrative-body']['fields']['nomination']['title']}</p>
						<TextField
							type='text'
							maxLength={30}
							className='content_edit_block_filter-inner'
							value={this.state.position.proposedAppoiment}
							onChange={this.handleProposedAppointmentChanged}
							onBlur={this.handleProposedAppointmentBlur}
							disabled={this.props.isReadOnly}
						/>
					</div>
					<div className='ms-Grid-col main-container__content_edit_block errors-container'>{validationErrors}</div>
				</div>
				<div className='ms-Grid-row positionForm_buttons' style={{ display: this.props.isReadOnly ? 'none' : '' }}>
					<div className='ms-Grid-col ms-md12 main-container__content_edit_block'>
						<button
							type='button'
							name='button'
							className='button-primary'
							onClick={this.props.isEdit ? this.handleUpdateClick : this.handleAddClick}
						>
							{this.props.isEdit
								? language['deeds']['form']['assignPosition']
								: language['deeds']['form']['assignPosition']}
						</button>
						<button type='button' name='button' className='button-cancel' onClick={this.cleanPosition}>
							{language['deeds']['form']['cleanForm']}
						</button>
					</div>
				</div>
			</div>
		);
	}

	private readonly onClearSearchPeople = (): void => {
		let position = { ...this.state.position };
		position.representativeId = null;
		position.representative.name = null;
		this.setState({ position: position });
	};

	private readonly cleanPosition = () => {
		let resetPosition = createEmptyBodyPosition();
		resetPosition.isPerson = this.state.position.isPerson;
		this.setState({ position: resetPosition, selectedPerson: null });
		this.props.setPosition(resetPosition);
	};

	private readonly handleAddClick = () => {
		let position = { ...this.state.position };
		if (this.validateForm(position)) {
			const trazas = `Se ha añadido un órgano de administración con los siguientes valores: ${this.state.trazas}`
			position.trazas = trazas;
			this.props.addPosition(position);
			this.setState({ errorMessages: [] });
		}
	};

	private readonly handleUpdateClick = () => {
		let position = { ...this.state.position };

		if (this.validateForm(position)) {
			const trazas = `Se ha editado el Apoderado con id ${position.id} con los siguientes cambios:\n ${this.state.trazas}`;
			position.trazas = trazas;
			this.props.updatePosition(position);
			this.props.setFinishedEdit(true);
			this.setState({ errorMessages: [] });
		}

	};

	private validateForm(position: BodyPosition) {
		let isPositionValid = true;
		let validationMessages = [];
		let language = utils.getResourcesFile(this.props.language);
		let isValidPositionType = position.positionTypeId > 0;
		let isValidRepresentative = position.representativeId > 0;
		let isValidCompany = position.companyId > 0;

		if (position.isPerson === true) {
			if (!isValidRepresentative) {
				validationMessages.push(language['generic']['messages']['required-person']);
			}

			if (!isValidPositionType) {
				validationMessages.push(language['generic']['messages']['required-positionType']);
			}

			isPositionValid = isValidPositionType && isValidRepresentative;
		} else {
			if (!isValidRepresentative) {
				validationMessages.push(language['generic']['messages']['required-body-representative']);
			}

			if (!isValidCompany) {
				validationMessages.push(language['generic']['messages']['required-society']);
			}

			if (!isValidPositionType) {
				validationMessages.push(language['generic']['messages']['required-positionType']);
			}

			isPositionValid = isValidPositionType && isValidRepresentative && isValidCompany;
		}

		if (!isPositionValid) {
			this.setState({ errorMessages: validationMessages });
		}
		return isPositionValid;
	}

	private readonly handleIsPersonChange = (event, value: any) => {
		let position = { ...this.state.position };
		position.isPerson = value === 'true';
		position.companyId = null;
		position.companyName = null;
		let trazas: string[] = [...this.state.trazas]
		value == false ? trazas.push(`Persona física\n`) : trazas.push('Persona Juridica\n');
		this.setState({ position: position, trazas: trazas });
		this.props.setPosition(position);
	};

	private readonly handleIsUndefinedClick = (event, checked?: boolean) => {
		let position = { ...this.state.position };
		position.undefined = checked;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Indefinido : ${checked}\n`);
		this.setState({ position: position, trazas: trazas });
		this.props.setPosition(position);
	};

	private readonly handleCompanyChange = (event, option): void => {
		let position = { ...this.state.position };
		position.companyId = parseInt(option?.key);
		position.companyName = option?.text;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Sociedad: ${option.text}`);
		this.setState({ position: position });
		this.props.setPosition(position);
	};

	private readonly handlePositionTypeChange = (event, option): void => {
		let position = { ...this.state.position };
		position.positionTypeId = parseInt(option?.key);
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Tipo de Cargo: ${option.text}\n`);
		this.setState({ position: position, trazas: trazas });
		this.props.setPosition(position);
	};

	private readonly handlePersonChange = (selected): void => {
		let position = { ...this.state.position };
		position.representativeId = parseInt(selected?.value);
		position.representative = createPerson();
		position.representative.name = selected.label;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Persona Administracion: ${selected.label}\n`);
		this.setState({ position: position, selectedPerson: selected, trazas: trazas });
		this.props.setPosition(position);
	};

	public readonly handleAppointmentDateChange = (value): void => {
		let position = { ...this.state.position };
		position.appointmentDate = value;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Fecha de Nombramiento: ${utils.formatDate(value)}\n`);
		this.setState({ position: position, trazas: trazas });
		this.props.setPosition(position);
	};

	private readonly onClearAppointmentDate = (): void => {
		this.handleAppointmentDateChange(null);
    };	

	public readonly handleAppoinmentEndDateChange = (value): void => {
		let position = { ...this.state.position };
		position.dateAppointmentFinal = value;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Fecha fin nombramiento: ${utils.formatDate(value)}\n`);
		this.setState({ position: position, trazas: trazas });
		this.props.setPosition(position);
	};

	private readonly onClearAppointmentEndDate = (): void => {
		this.handleAppoinmentEndDateChange(null);
    };	

	public readonly handleTerminationDateChange = (value): void => {
		let position = { ...this.state.position };
		position.terminationDate = value;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Fecha de cese: ${utils.formatDate(value)}\n`);
		this.setState({ position: position, trazas: trazas });
		this.props.setPosition(position);
	};

	private readonly onClearTerminationDate = (): void => {
		this.handleTerminationDateChange(null);
    };	

	public readonly handleProposedAppointmentChanged = (event): void => {
		let position = { ...this.state.position };
		position.proposedAppoiment = event.target.value;
		let trazas: string[] = [...this.state.trazas]
		trazas.push(`Propuesta de Nombramiento: ${event.target.value}\n`);
		this.setState({ position: position });
		this.props.setPosition(position);
	};
	public readonly handleProposedAppointmentBlur = (event): void => {
		let position = { ...this.state.position };
		position.proposedAppoiment = event.target.value?.toUpperCase();
		this.setState({ position: position });
		this.props.setPosition(position);
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filter(inputValue));
		}, 1000);
	};

	private readonly filter = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.peopleNames && this.props.peopleNames.length > 0) {
			this.props.peopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};
}
