import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { PeoplePage } from './page';
import { getPeopleAction } from './actions/getPeople';
import { deletePersonAction } from './actions/deletePerson';
import { PersonFilter } from '../../../model/filters/PersonFilter';
import { Language, UserInfo, UserState } from '../../../model';
import { getBusinessNamesAction } from './actions/getBusiness';
import { exportToExcelAction } from './actions/exportToExcel';
import { getPeopleNamesAction } from './actions/getPeopleNames';

const mapStateToProps = (state: StateReducer) => ({
	peopleCollection: state.peopleCollection,
	csvDownloadLink: state.csvDownloadLink,
	user: state.user,
	language: state.language,
	business: state.businessNames,
	currentUser: state.users,
	peopleNames: state.peopleNames,
	filter: state.personFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	getBusiness: () => dispatch(getBusinessNamesAction()),
	getPeople: (filter: PersonFilter, pageNumber: number, itemsPage: number) => dispatch(getPeopleAction(filter, pageNumber, itemsPage)),
	deletePerson: (filter: PersonFilter, language: Language, id: number, currentUser: UserInfo) => dispatch(deletePersonAction(filter, language, id, currentUser)),
	exportToExcel: (filter: PersonFilter) => dispatch(exportToExcelAction(filter)),
	getPeopleNames: (filter: string) => dispatch(getPeopleNamesAction(filter)),
});

export const PeoplePageContainer = connect(mapStateToProps, mapDispatchToProps)(PeoplePage);
