import { GenericApi, SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { Constants } from '../../../../common/const/constants';
import { Language, UserInfo } from '../../../../model';
import { PersonFilter } from '../../../../model/filters/PersonFilter';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
import { getPeopleAction } from '../../list/actions/getPeople';

export const deletePersonAction = (filter: PersonFilter, currentLanguage: Language, id: number, currentUser: UserInfo) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    GenericApi.DeleteAsync(ApiUrls.People, id).then((result: any) => {
        if (!result.error) {
            showSuccess(language['people']['form']['messages']['success-delete']);
            const trazaPerson = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Delete Person",
                Fecha: new Date().toDateString(),
                Traza: `Se ha eliminado la persona ${id}`,
                IdRegistro: id.toString(), 
            }
            SendTraceToBBDD(trazaPerson)
        } else {
            showError(language['people']['form']['messages']['error-delete'] + language['generic']['error'][result.error]);
        }

        dispatch(getPeopleAction(filter, 1, Constants.ItemsPerPage));
        dispatch(deletePersonCompleted(result));
    });

};

const deletePersonCompleted = (result: boolean) => ({
    type: actionTypes.DELETE_PERSON,
    payload: result
});