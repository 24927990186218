import { showSuccess, showError } from '../../../utils/extensions';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { getResourcesFile } from '../../../utils/extensions';
import { PositionTypeFilter } from '../../../model/filters';
import { Language, UserInfo } from '../../../model';
import {getPositionTypesCollectionAction} from './getPositionTypeCollection';
import { Constants } from '../../../common/const/constants';

export const deletePositionTypeAction = (filter: PositionTypeFilter, currentLanguage: Language, id: number, currentUser: UserInfo) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.PositionTypes, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['position-types']['messages']['success-delete']);
			const trazaPositionType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Delete Position Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el tipo de cargo: ${id}`,
                IdRegistro: id.toString(),
            }
            SendTraceToBBDD(trazaPositionType)
		} else {
			showError(language['position-types']['messages']['error-delete'] + result.error);
		}

		dispatch(getPositionTypesCollectionAction(filter, 1, Constants.ItemsPerPage));
		dispatch(deletePositionTypeCompleted(result));
	});
};
const deletePositionTypeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_POSITION_TYPE,
	payload: result,
});
