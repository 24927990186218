import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react/lib/components/MessageBar';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { ButtonGroupComponent } from '../../../common/buttonGroup/buttonGroup';
import { BaseOptionModel, BaseType, createPerson, Language, Person, User, UserInfo, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { BusinessTypes } from '../../../model/enums/businessTypes';
import { PersonTypes } from '../../../model/enums/personType';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { MeApi } from '../../../api/me';

export interface IProps {
	person: Person;
	personSave: number;
	business: BaseType[];
	user: User;
	language: Language;
	loading: boolean;
	nameAzureAd: string;
	getBusiness: () => Promise<BaseType[]>;
	getPerson: (id: number) => Promise<Person>;
	getNameAzureAd: (email: string) => Promise<string>;
	savePerson: (person: Person, language: Language, currentUser: UserInfo, previousPerson?: Person) => Promise<number>;
}

export interface IState {
	personForm: Person;
	errorMessages: string[];
	isInternalPerson: boolean;
	nameAzureAd: string;
	visibleError: boolean;
	nameExt: string;
	surnameExt: string;
	editMode: boolean;
	existsPersonControl: boolean;
}

class PersonFormComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			personForm: createPerson(),
			errorMessages: [],
			isInternalPerson: true,
			nameAzureAd: '',
			nameExt: '',
			surnameExt: '',
			visibleError: false,
			editMode: false,
			existsPersonControl: false,
		};
	}

	public componentDidMount() {
		if (this.props.match.params['id'] != null) {
			this.props.getPerson(this.props.match.params['id']);
			this.setState({ editMode: true });
		} else {
			this.setState({ existsPersonControl: true });
		}
		this.props.getBusiness();
	}

	public componentWillReceiveProps(props) {
		if (props.person !== this.props.person) {
			let isInternalPerson = props.person.personType === PersonTypes.Interna;
			let nameExt = props.person.name;
			let surnameExt = '';
			if (!isInternalPerson) {
				let nameBack = props.person.name;
				let arrayName = nameBack.split(',');
				if(arrayName !== undefined && arrayName.length > 1){
					nameExt = arrayName[1].trim();
					surnameExt = arrayName[0].trim();
				}

			}
			this.setState({ personForm: { ...props.person }, nameExt, surnameExt, isInternalPerson, existsPersonControl: true });
		}
	}

	public componentDidUpdate(prevProps) {
		if (this.props.nameAzureAd !== prevProps.nameAzureAd) {
			let person = { ...this.state.personForm };
			person.name = this.props.nameAzureAd;
			this.setState({ visibleError: false, personForm: person });
		}
	}

	public render() {
		let person: Person = { ...this.state.personForm };
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let options: BaseOptionModel[] = [
			{ label: language['PersonType']['internal'], value: 1 },
			{ label: language['PersonType']['external'], value: 2 },
		];

		let business = this.filterBusinessCombo(person.personType);
		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});
		return (
			<div>
				<div className='ms-Grid-row'>
					<h1>
						{this.props.match.params['id'] == null
							? language['people']['form']['text-add']
							: language['people']['form']['text-edit']}
						{language['people']['form']['title']}
					</h1>
				</div>
				<div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10'>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col ms-sm12 ms-xl12'>
							<div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl3'>
								<p>{language['people']['form']['fields']['name']['person-type']}</p>
								<ButtonGroupComponent
									onChange={this.onToggleChange}
									options={options}
									value={person.personType}
									className={'custom-toggle-buttons'}
								/>
							</div>
							<div
								className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl3'
								style={{ display: this.state.isInternalPerson ? '' : 'none' }}
							>
								<p>{language['people']['form']['fields']['email']['title']}</p>
								<TextField
									type='text'
									value={person.email}
									onChange={this.handleMailChange}
									onBlur={this.handleMailBlur}
									onKeyPress={(event)=>{if(event.key === 'Enter'){this.handleMailBlur(event)}}}
									validateOnLoad={false}
									validateOnFocusOut
									maxLength={100}
								/>
							</div>
							<div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl4'>
								<MessageBarComponent
									Message={language['generic']['messages']['required-valid-email-incorrect']}
									Type={MessageBarType.error}
									MessageVisible={this.state.visibleError}
									close={null}
								/>
							</div>
							<div className='content_edit_block_filter ms-Grid-row flex-option'>
								<div className='ms-Grid-col ms-sm12 ms-md12 ms-xl3'>
									<p>{language['people']['form']['fields']['name']['title']}</p>
									<TextField
										type='text'
										disabled={this.state.isInternalPerson}
										value={this.state.isInternalPerson ? person.name : this.state.nameExt}
										onChange={this.handleNameChange}
										validateOnLoad={false}
										validateOnFocusOut
										maxLength={100}
									/>
								</div>
								{/* <div hidden={this.state.isInternalPerson} className='ms-Grid-col ms-sm12 ms-md12 ms-xl3'>
									<p>{language['people']['form']['fields']['surname']['title']}</p>
									<TextField
										type='text'
										value={this.state.surnameExt}
										onChange={this.handleSurnameChange}
										validateOnLoad={false}
										validateOnFocusOut
										hidden={this.state.isInternalPerson}
										maxLength={100}
									/>
								</div> */}
							</div>
							<div className='content_edit_block_filter ms-Grid-row flex-option'>
								<div className='ms-Grid-col ms-sm12 ms-md12 ms-xl3'>
									<p>{language['people']['form']['fields']['dni']['title']}</p>
									<TextField
										type='text'
										value={person.dni}
										onChange={this.handleDniChange}
										onBlur={this.handleDniBlur}
										onGetErrorMessage={this.getInvalidDniErrorMessage}
										description={this.state.isInternalPerson ? language['people']['form']['fields']['dni']['message'] : language['people']['form']['fields']['dni-external']['message']}
										validateOnLoad={false}
										validateOnFocusOut
										maxLength={15}
									/>
								</div>
								<div className='ms-Grid-col ms-sm12 ms-md12 ms-xl3'>
									<p>{language['people']['form']['fields']['passport']['title']}</p>
									<TextField
										type='text'
										value={person.passport}
										onChange={this.handlePassportChange}
										onBlur={this.handlePassportBlur}
										validateOnLoad={false}
										validateOnFocusOut
										maxLength={30}
									/>
								</div>
							</div>
							<div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl3'>
								<p>{this.state.isInternalPerson ? language['people']['form']['fields']['nacionality']['title'] : language['people']['form']['fields']['nacionality-external']['title']}</p>
								<TextField
									type='text'
									value={person.nationality}
									onChange={this.handleNationalityChange}
									onBlur={this.handleNationalityBlur}
									maxLength={30}
								/>
							</div>
							<div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl3'>
								<p>{language['people']['form']['fields']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business.sort((a, b) => a.text.localeCompare(b.text))}
									placeholder={language['authorizations']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleBusinessChange}
									selectedKey={person.tradeId}
									disabled={person.personType === 2 ? true : false}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='ms-Grid-row'>
					<hr />
					{validationErrors}
					{isAdmin && (
						<button
							type='button'
							className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
							name='savePerson'
							onClick={this.savePerson}
						>
							{language['people']['form']['save-button']}
						</button>
					)}
					<button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
						{language['people']['form']['cancel-button']}
					</button>
				</div>
			</div>
		);
	}

	private readonly savePerson = (): void => {
		let person: Person = { ...this.state.personForm };
		if (this.validatePerson(person)) {
			person.name = this.state.isInternalPerson ? person.name : this.state.nameExt;
			person.email = this.state.isInternalPerson ?  person.email : '';
			MeApi.GetUserInfo().then((user)=>{
				this.props.savePerson(person, this.props.language, user, this.props.person);
			})
		}
	};

	private readonly handleNameChange = (newName): void => {
		let nameExt = newName.target.value;
		let person: Person = { ...this.state.personForm };
		person.name = newName.target.value;
		this.setState({ nameExt, personForm: person });
	};


	private readonly handleMailChange = async (mail): Promise<void> => {		
		let person: Person = { ...this.state.personForm };
		let newMail: string = mail.target.value.toLowerCase();
		let isInternalPerson = person.personType === PersonTypes.Interna;
		person.email = newMail;
		this.setState({ personForm: person, isInternalPerson: isInternalPerson });
	};

	private readonly handleMailBlur = async (mail): Promise<void> => {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let person: Person = { ...this.state.personForm };
		let newMail: string = mail.target.value.toLowerCase();
		let isInternalPerson = person.personType === PersonTypes.Interna;
		let visibleError = false;
		let errorMessages = this.state.errorMessages;

		if (isInternalPerson) {			
			const name = await this.props.getNameAzureAd(newMail);
			visibleError = name === undefined || name === '';
			if (visibleError) {
				person.name = '';
				let found = errorMessages.findIndex(
					(element) =>
						element === language['generic']['messages']['required-valid-email'] ||
						element === language['generic']['messages']['required-valid-email-incorrect'],
				);
				if (found > -1) {
					errorMessages.splice(found, 1);
				}
			} else {
				person.name = name;
			}			
		}
		this.setState({ visibleError, errorMessages });
	}

	private readonly handleDniChange = (newDni): void => {
		let person: Person = { ...this.state.personForm };
		person.dni = newDni.target.value;
		this.setState({ personForm: person });
	};
	private readonly handleDniBlur = (newDni): void => {
		let person: Person = { ...this.state.personForm };
		person.dni = newDni.target.value.toUpperCase();
		this.setState({ personForm: person });
	};

	private readonly handlePassportChange = (newPassport): void => {
		let person: Person = { ...this.state.personForm };
		person.passport = newPassport.target.value;
		this.setState({ personForm: person });
	};
	private readonly handlePassportBlur = (newPassport): void => {
		let person: Person = { ...this.state.personForm };
		person.passport = newPassport.target.value.toUpperCase();
		this.setState({ personForm: person });
	};

	private readonly handleNationalityChange = (newNationality): void => {
		let person: Person = { ...this.state.personForm };
		person.nationality = newNationality.target.value;
		this.setState({ personForm: person });
	};
	private readonly handleNationalityBlur = (newNationality): void => {
		let person: Person = { ...this.state.personForm };
		person.nationality = newNationality.target.value.toUpperCase();
		this.setState({ personForm: person });
	};

	private readonly handleBusinessChange = (event, option): void => {
		let person: Person = { ...this.state.personForm };
		person.tradeId = option?.key;
		this.setState({ personForm: person });
	};

	private readonly validatePerson = (person: Person) => {
		let errorMessages: string[] = [];
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let hasDniAndIsValid = this.validateDNI(person.dni).length === 0;
		let hasPassport = person.passport && person.passport.length > 0;
		let hasTrade = person.tradeId > 0;
		let hasNameExt = !this.state.isInternalPerson ? !utils.isNullOrEmpty(this.state.nameExt) : !utils.isNullOrEmpty(person.name);
		let hasName = !this.state.isInternalPerson ? hasNameExt : hasNameExt;
		let hasNacionality = !utils.isNullOrEmpty(person.nationality);
		let hasEmail = this.validateEmail(person?.email) && this.state.isInternalPerson;
		let hasError = this.state.visibleError;
		let isPersonValid;
		if (this.state.isInternalPerson) {
			isPersonValid =
				hasTrade &&
				hasName &&
				(hasDniAndIsValid || hasPassport) &&
				(hasEmail || !this.state.isInternalPerson) &&
				hasNacionality &&
				!hasError;
		} else {
			isPersonValid =
				hasTrade &&
				hasName &&
				(hasEmail || !this.state.isInternalPerson) &&
				!hasError;
		}

		if (!hasEmail && this.state.isInternalPerson) {
			errorMessages.push(language['generic']['messages']['required-valid-email']);
		}

		if (!hasNameExt && !this.state.isInternalPerson) {
			errorMessages.push(language['generic']['messages']['required-valid-name']);
		}

		if (hasError && hasEmail) {
			errorMessages.push(language['generic']['messages']['required-valid-email-incorrect']);
			hasError = false;
		}
		if (this.state.isInternalPerson && !hasPassport && !hasDniAndIsValid) {
			errorMessages.push(language['generic']['messages']['required-valid-dni']);
		}
		if (this.state.isInternalPerson && !hasNacionality) {
			errorMessages.push(language['generic']['messages']['required-valid-nacionality']);
		}
		if (!hasTrade) {
			errorMessages.push(language['generic']['messages']['required-trade']);
		}
		this.setState({ errorMessages, visibleError: hasError });

		return isPersonValid;
	};

	private readonly getInvalidDniErrorMessage = (value: string): string => {
		if (value.length > 0) {
			return this.validateDNI(value);
		}
	};

	private readonly onToggleChange = (event, value: any): void => {
		let errorMessages = [];
		let visibleError = this.state.visibleError;
		let person: Person = { ...this.state.personForm };
		person.personType = parseInt(value);
		person.tradeId = value === PersonTypes.Externa ? BusinessTypes.Externo : 0;
		let isInternalPerson = person.personType === PersonTypes.Interna;
		person.email = isInternalPerson ? '' : person.email;
		person.name = isInternalPerson ? '' : this.state.nameExt;
		if (isInternalPerson) {
			this.props.getNameAzureAd('');
		} else {
			visibleError = false;
		}
		this.setState({
			personForm: person,
			isInternalPerson,
			errorMessages,
			visibleError,
		});
	};

	private readonly filterBusinessCombo = (personType: number): IComboBoxOption[] => {
		let business: IComboBoxOption[] = [];
		this.props.business
			.filter((x: BaseType) => x.id !== (this.state.personForm.personType === PersonTypes.Interna ? BusinessTypes.Externo : 0))
			.forEach((item: BaseType) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});

		return business;
	};

	private readonly validateDNI = (dni: string): string => {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let numberString: any;
		let number: any;
		let dniLetter: string;
		let expectedLetter: string;
		let dniRegex: RegExp;

		dniRegex = /^\d{8}[a-zA-Z]$/;

		if (dniRegex.test(dni) === true) {
			numberString = dni.substr(0, dni.length - 1);
			dniLetter = dni.substr(dni.length - 1, 1);
			number = numberString % 23;
			expectedLetter = 'TRWAGMYFPDXBNJZSQVHLCKET';
			expectedLetter = expectedLetter.substring(number, number + 1);
			if (expectedLetter !== dniLetter.toUpperCase()) {
				return language['generic']['messages']['incorrect-dni-letter'];
			} else {
				return '';
			}
		} else {
			return language['generic']['messages']['incorrect-dni'];
		}
	};

	private readonly validateEmail = (email: string): boolean => {
		var re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			// /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
}

export const PersonForm = withRouter(PersonFormComponent);
