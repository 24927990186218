import { connect } from 'react-redux';
import { Language, Person, UserInfo, UserState } from '../../../model';
import { StateReducer } from '../../../reducers';
import { getBusinessNamesAction } from '../list/actions/getBusiness';
import { getNameAzureAdAction, getPersonAction, savePersonAction } from './actions/index';
import { PersonForm } from './page';

const mapStateToProps = (state: StateReducer) => ({
	person: state.person,
	personSave: state.personSave,
	user: state.user,
	language: state.language,
	business: state.businessNames,
	loading: state.http?.inProgress,
	nameAzureAd: state.nameAzureAd
});

const mapDispatchToProps = (dispatch: any) => ({
	getBusiness: () => dispatch(getBusinessNamesAction()),
	getPerson: (id: number) => dispatch(getPersonAction(id)),
	getNameAzureAd: (email: string) => dispatch(getNameAzureAdAction(email)),
	savePerson: (person: Person, language: Language, currentUser: UserInfo, previousPerson?: Person) => dispatch(savePersonAction(person, language, currentUser, previousPerson))
});

export const PersonFormContainer = connect(mapStateToProps,
	mapDispatchToProps)(PersonForm);
