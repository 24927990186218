import { MessageBarType } from 'office-ui-fabric-react/lib/components/MessageBar';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { NotaryPeople, Language, User, createNotaryPeople, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { MeApi } from '../../../api/me';

export interface IProps {
	notaryPeople: NotaryPeople;
	notaryPeopleSave: number;
	user: User;
	language: Language;
	loading: boolean;
	getNotaryPeople: (id: number) => Promise<NotaryPeople>;
	//getNameAzureAd: (email: string) => Promise<string>;
	saveNotaryPeople: (notary: NotaryPeople, language: Language, currentUser: UserState, previousNotaryPeople?: NotaryPeople) => Promise<number>;
}

export interface IState {
	notaryPeopleForm: NotaryPeople;
	errorMessages: string[];
	visibleError: boolean;
	nameExt: string;
	surnameExt: string;
	editMode: boolean;
	existsNotaryPeopleControl: boolean;
}

class NotaryPeopleFormComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			notaryPeopleForm: createNotaryPeople(),
			errorMessages: [],
			nameExt: '',
			surnameExt: '',
			visibleError: false,
			editMode: false,
			existsNotaryPeopleControl: false,
		};
	}

	public componentDidMount() {
		if (this.props.match.params['id'] != null) {
			this.props.getNotaryPeople(this.props.match.params['id']);
			this.setState({ editMode: true });
		} else {
			this.setState({ existsNotaryPeopleControl: true });
		}
	}

	public componentWillReceiveProps(props) {
		if (props.notaryPeople !== this.props.notaryPeople) {
			let nameExt = props.notaryPeople.name;
			let surnameExt = '';
		
				let nameBack = props.notaryPeople.name;
				let arrayName = nameBack.split(',');
				if(arrayName !== undefined && arrayName.length > 1){
					nameExt = arrayName[1].trim();
					surnameExt = arrayName[0].trim();
				}

			
			this.setState({ notaryPeopleForm: { ...props.notaryPeople }, nameExt, surnameExt, existsNotaryPeopleControl: true });
		}
	}

	public componentDidUpdate(prevProps) {

	}

	public render() {

		let notaryPeople: NotaryPeople = { ...this.state.notaryPeopleForm };
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});
		return (
			<div>
				<div className='ms-Grid-row'>
					<h1>
						{this.props.match.params['id'] == null
							? language['notaryPeople']['form']['text-add']
							: language['notaryPeople']['form']['text-edit']}
						{language['notaryPeople']['form']['title']}
					</h1>
				</div>
				<div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10'>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col ms-sm12 ms-xl12'>
							{/* <div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl3'>
								<p>{language['notaryPeople']['form']['fields']['email']['title']}</p>
								<TextField
									type='text'
									value={notaryPeople.email}
									onChange={this.handleMailChange}
									validateOnLoad={false}
									validateOnFocusOut
									maxLength={100}
								/>
							</div>
							<div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl4'>
								<MessageBarComponent
									Message={language['generic']['messages']['required-valid-email-incorrect']}
									Type={MessageBarType.error}
									MessageVisible={this.state.visibleError}
									close={null}
								/>
							</div> */}
							<div className='content_edit_block_filter ms-Grid-row flex-option'>
								<div className='ms-Grid-col ms-sm12 ms-md12 ms-xl5'>
									<p>{language['notaryPeople']['form']['fields']['name']['title']}</p>
									<TextField
										type='text'
										value={notaryPeople.name}
										onChange={this.handleNameChange}
										validateOnLoad={false}
										validateOnFocusOut
										maxLength={100}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='ms-Grid-row'>
					<hr />
					{validationErrors}
					{isAdmin && (
						<button
							type='button'
							className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
							name='savePerson'
							onClick={this.saveNotary}
						>
							{language['notaryPeople']['form']['save-button']}
						</button>
					)}
					<button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
						{language['notaryPeople']['form']['cancel-button']}
					</button>
				</div>
			</div>
		);
	}

	private readonly saveNotary = (): void => {
		let notaryPeople: NotaryPeople = { ...this.state.notaryPeopleForm };
		if (this.validateNotary(notaryPeople)) {
			MeApi.GetUserInfo().then((result)=>{
				this.props.saveNotaryPeople(notaryPeople, this.props.language, result, this.props.notaryPeople);
			})
		}
	};

	private readonly handleNameChange = (newName): void => {
		let nameExt = newName.target.value;
		let notaryPeople: NotaryPeople = { ...this.state.notaryPeopleForm };
		notaryPeople.name = newName.target.value;
		this.setState({ nameExt, notaryPeopleForm: notaryPeople });
	};

	private readonly validateNotary = (notaryPeople: NotaryPeople) => {
		let errorMessages: string[] = [];
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

        let hasName = !utils.isNullOrEmpty(notaryPeople?.name);
		//let hasEmail = this.validateEmail(notaryPeople?.email);
		let hasError = this.state.visibleError;
		//let isPersonValid = hasName && hasEmail && !hasError;
		let isPersonValid = hasName && !hasError;

        if (!hasName) {
            errorMessages.push(language['generic']['messages']['required-valid-name']);
        }            
		// if (!hasEmail) {
		// 	errorMessages.push(language['generic']['messages']['required-valid-email']);
		// }
		// if (hasError && hasEmail) {
		// 	errorMessages.push(language['generic']['messages']['required-valid-email-incorrect']);
		// 	hasError = false;
		// }
		this.setState({ errorMessages, visibleError: hasError });

		return isPersonValid;
	};

	/* private readonly validateEmail = (email: string): boolean => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};    Clean Warnings Compilation   */   
}

export const NotaryPeopleForm = withRouter(NotaryPeopleFormComponent);
