import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { onlyDocumentTypesModule } from '../../common/const/rolesAssignments';
import { userRoles } from '../../utils/enums/userRolesEnum';
import * as utils from '../../utils/extensions';
import { languages } from '../../common/const/resources';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { BaseType, DocumentTypeCollection, Language, User, UserInfo, UserState } from '../../model';
import { UnauthorizedView } from '../../common/unauthorizedView/unauthorizedView';
import { Link } from 'react-router-dom';
import { SpinnerContainer } from '../../common/spinner';
import { Icon } from 'office-ui-fabric-react';
import { NoResultsComponent } from '../../common/noResults/noResults';
import { DocumentTypeFilter } from '../../model/filters';
import { DocumentTypeFilterComponent } from './crud/documentTypesFilterComponent';
import { DocumentTypeDataGrid } from './crud/documentTypesGridcomponent';
import { routeUrls } from '../../common/const/routes';

export interface IProps {
	documentType: DocumentType;
	documentTypeCollection: DocumentTypeCollection;
	documentTypeFilter: DocumentTypeFilter;
	deepTypeResponse: number | boolean;
	user: User;
	language: Language;
	documentTypeNames: BaseType[];
	currentUser: UserState;
	filter: DocumentTypeFilter;
	getDocumentTypePrimaries: () => Promise<DocumentType[]>;
	deleteDocumentType: (filter: DocumentTypeFilter, language: Language, id: number, currentUser: UserInfo) => Promise<boolean>;
	exportToExcel: (filter: DocumentTypeFilter) => Promise<any>;
	getDocumentTypeCollection: (filter: DocumentTypeFilter, pageNumber: number, itemsPage: number) => Promise<DocumentTypeCollection>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	collapsed: boolean;
	actualFilter: DocumentTypeFilter;
}

export class DocumentTypesHome extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			collapsed: false,
			actualFilter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyDocumentTypesModule);
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });

		if (this.props.location.state != null) {
			this.props.getDocuments(this.props.location.state.documentFilter, page);
		} else {
			this.props.getDocumentTypeCollection(this.props.filter, page, this.props.filter.ItemsPage);
		}
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlyDocumentTypesModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getDocumentTypeCollection(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['documentType']['title']}</h1>
							{isAdmin && (
								<Link to='/documentTypes/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['documentType']['new']}</span>
								</Link>
							)}
						</div>
						<div className='ms-Grid'>
							<DocumentTypeFilterComponent
								filter={this.state.actualFilter}
								onchangeFilter={this.handleFilterChange}
								getDocumentTypeCollection={this.props.getDocumentTypeCollection}
								getDocumentTypePrimaries={this.props.getDocumentTypePrimaries}
								documentTypeCollection={this.props.documentTypeCollection}
								documentTypePrimaries={this.props.documentTypePrimaries}
								language={this.props.language}
								collapsed={this.state.collapsed}
								user={this.props.currentUser}
								itemsPage={this.state.actualFilter.ItemsPage}
							/>
							{this.props.documentTypeCollection.list?.length > 0 ? (
								<DocumentTypeDataGrid
									documentTypeCollection={this.props.documentTypeCollection}
									documentTypePrimaries={this.props.documentTypePrimaries}
									onchangeFilter={this.handleFilterChange}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.actualFilter}
									setDocumentTypeFilter={this.props.setDocumentTypeFilter}
									deleteDocumentType={this.props.deleteDocumentType}
									page={page}
									getDocumentTypeCollection={this.props.getDocumentTypeCollection}
									getDocumentTypePrimaries={this.props.getDocumentTypePrimaries}
									exportToExcel={this.props.exportToExcel}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['documentType']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}

	public readonly handleFilterChange = (newfilter: DocumentTypeFilter): void => {
		this.setState({ actualFilter: newfilter });
		this.props.history.push(routeUrls.documentTypesPagination + 1);
	};
}
