import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { PositionType, Language, UserInfo } from '../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const savePositionTypeAction = (positionType: PositionType, currentLanguage: Language, currentUser: UserInfo, previousPositionType?: PositionType) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (positionType.id > 0) {
        handleUpdatePositionType(positionType, language, dispatch, currentUser, previousPositionType);
    } else {
        handleCreatePositionType(positionType, language, dispatch, currentUser);
    }
};


const handleUpdatePositionType = (positionType: PositionType, language: any, dispatch, currentUser: UserInfo, previousPositionType: PositionType) => {
    GenericApi.UpdateAsync(ApiUrls.PositionTypes, positionType).then((result: any) => {
        if (result.error) {
            showError(language['position-types']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(savePositionTypeCompleted(result));
        }
        else {
            showSuccess(language['position-types']['messages']['success-update']);
            browserHistory.goBack();
            dispatch(savePositionTypeCompleted(result));
            const trazaPositionType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Position Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado la escritura ${positionType.id}, con los siguientes cambios: ${objectToString(compareObjects(previousPositionType, positionType))}`,
                IdRegistro: positionType.id.toString()
            }
            SendTraceToBBDD(trazaPositionType)
        }
    });
}

const handleCreatePositionType = (positionType: PositionType, language: any, dispatch, currentUser: UserInfo) => {
    GenericApi.AddAsync(ApiUrls.PositionTypes, positionType).then((result: any) => {
        if (result > 0) {
            showSuccess(language['position-types']['messages']['success']);
            browserHistory.goBack();
            dispatch(savePositionTypeCompleted(result));
            positionType.id = result;
            const trazaPositionType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Create Position Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el tipo de cargo: ${result}`,
                IdRegistro: result.toString()
            }
            SendTraceToBBDD(trazaPositionType)
        } else {
            showError(language['position-types']['messages']['error'] + " " + language['generic']['error'][result.error]);
            dispatch(savePositionTypeCompleted(result));
        }
    });
}

const savePositionTypeCompleted = (result: number) => ({
    type: actionTypes.SAVE_POSITION_TYPE_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});