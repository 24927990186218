import { runtimeVariables } from "../../globalEnvVariables";

export const userRoles = {
    "adminMaster" : runtimeVariables._instance.adminMaster,
    "admin" : runtimeVariables._instance.admin,
    "ajAeropuertos" : runtimeVariables._instance.ajAeropuertos,
    "ajAeropuertosH" : runtimeVariables._instance.ajAeropuertosH,
    "ajCintra" : runtimeVariables._instance.ajCintra,
    "ajCintraH" : runtimeVariables._instance.ajCintraH,
    "ajConstruccion" : runtimeVariables._instance.ajConstruccion,
    "ajConstruccionH" : runtimeVariables._instance.ajConstruccionH,
    "ajCorporacion" : runtimeVariables._instance.ajCorporacion,
    "ajCorporacionH" : runtimeVariables._instance.ajCorporacionH,
    "tesoAeropuertos" : runtimeVariables._instance.tesoAeropuertos,
    "tesoCintra" : runtimeVariables._instance.tesoCintra,
    "tesoConstruccion" : runtimeVariables._instance.tesoConstruccion,
    "tesoCorporacion" : runtimeVariables._instance.tesoCorporacion,
    "ajFeim" : runtimeVariables._instance.ajFeim,
    "ajFeimH" : runtimeVariables._instance.ajFeimH,
}


// export enum userRoles {
//     adminMaster = 'SG_BA0613_01_PP',
//     admin = 'SG_BA0613_02_PP',
//     ajAeropuertos = 'SG_BA0613_03_PP',
//     ajAeropuertosH = 'SG_BA0613_04_PP',
//     ajCintra = 'SG_BA0613_05_PP',
//     ajCintraH = 'SG_BA0613_06_PP',
//     ajConstruccion = 'SG_BA0613_07_PP',
//     ajConstruccionH = 'SG_BA0613_08_PP',
//     ajCorporacion = 'SG_BA0613_09_PP',
//     ajCorporacionH = 'SG_BA0613_10_PP',
//     tesoAeropuertos = 'SG_BA0613_11_PP',
//     tesoCintra = 'SG_BA0613_12_PP',
//     tesoConstruccion = 'SG_BA0613_13_PP',
//     tesoCorporacion = 'SG_BA0613_14_PP',
//     ajFeim = 'SG_BA0613_15_PP',
//     ajFeimH = 'SG_BA0613_16_PP'
// }