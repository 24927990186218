import { connect } from 'react-redux';
import { FileSp, Language, Power, PowerRevocationFilter, UserState } from '../../../model';
import { Modules } from '../../../model/enums';
import { StateReducer } from '../../../reducers';
import { getPowerTypeNamesAction } from '../../deeds/form/actions/getPowerTypeNames';
import { getPowersAction } from '../../representatives/list/actions/getPowers';
import { getPowerExerciseNamesAction } from '../list/actions/getPowerExerciseNames';
import { deleteFileSpAction } from './actions/deleteSpFile';
import { getBusinessNamesAction } from './actions/getBusiness';
import { getTradeCompaniesAction } from './actions/getCompanies';
import { getCompaniesSearchAction } from './actions/getCompaniesSearchAction';
import { getDepartmentsAction } from './actions/getDepartments';
import { getNotaryDocumentsAction } from './actions/getNotaryDocuments';
import { getPeopleNamesAction } from './actions/getPeopleNames';
import { getNotaryPeopleNamesAction } from './actions/getNotaryNames';
import { getPowerAction } from './actions/getPower';
import { getPowerSubjectsAction } from './actions/getPowerSubjects';
import { savePowerAction } from './actions/savePower';
import { RepresentativeForm } from './page';
import { getLastBoxAction } from './actions/getLastBox';

const moduleId = Modules.Powers;

const mapStateToProps = (state: StateReducer) => ({
	power: state.powerRepresentatives,
	representative: state.representative,
	representativeDetail: state.representativeDetailFilter,
	companies: state.tradeCompanies,
	notaryDocuments: state.notaryDocuments,
	peopleNames: state.peopleNames,
	notaryPeopleNames: state.notaryPeopleNames,
	powers: state.powerNames,
	powerTypeNames: state.powerTypeNames,
	language: state.language,
	user: state.user,
	currentUser: state.users,
	exerciseTypes: state.legalForms,
	business: state.businessNames,
	departments: state.departments,
	powerExerciseNames: state.powerExerciseNames,
	loading: state.http?.inProgress,
	powerSubjects: state.powerSubjects,
	companiesSearch:state.companiesSearch,
	lastBox: state.lastBox
});

const mapDispatchToProps = (dispatch: any) => ({
	getPower: (id: number) => dispatch(getPowerAction(id)),
	getCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getNotaryDocuments: () => dispatch(getNotaryDocumentsAction()),
	getPeopleNames: (filter: string) => dispatch(getPeopleNamesAction(filter)),
	getNotaryPeopleNames: (filter: string) => dispatch(getNotaryPeopleNamesAction(filter)),
	getCompaniesSearch: (filter: string) => dispatch(getCompaniesSearchAction(filter)),
	getPowers: () => dispatch(getPowersAction()),
	getPowerTypes: () => dispatch(getPowerTypeNamesAction()),
	savePower: (power: Power, language: Language, currentUser: UserState, objetoTrazas?: string) => dispatch(savePowerAction(power, language, currentUser , objetoTrazas)),
	deleteFileSp: (fileSp: FileSp) => dispatch(deleteFileSpAction(fileSp)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
	getPowerExerciseNames: () => dispatch(getPowerExerciseNamesAction()),
	getDepartments: () => dispatch(getDepartmentsAction()),
	getPowerSubjects: (filter: PowerRevocationFilter) => dispatch(getPowerSubjectsAction(filter)),
	getLastBox:() => dispatch(getLastBoxAction())
});

export const RepresentativeFormContainer = connect(mapStateToProps, mapDispatchToProps)(RepresentativeForm);
