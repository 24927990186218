import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { getCompaniesNamesAction } from '../actions/getCompaniesNamesAction';
import { getBusinessAction } from '../actions/getBusiness';
import { getCountriesAction } from '../actions/getCountries';
import { getOriginalsAction } from '../actions/getOriginals';
import { getDocumentTypePrimariesAction } from '../actions/getDocumentTypePrimaries';
import { getDocumentTypeSubTypeAction } from '../actions/getDocumentTypeSubTypes';
import { exportToExcelAction } from '../actions/exportToExcel';
import { getDocumentationAction } from '../actions/getDocumentation';
import { deleteDocumentationAction } from '../actions/deleteDocumentation';
import { DocumentationPage } from './page';
import { DocumentationFilter } from '../../../model/filters/DocumentationFilter';
import { Language } from '../../../model/Language';
import { Modules } from '../../../model/enums';
import { getTradeCompaniesAction } from '../actions/getCompanies';
import { UserState } from '../../../model';

const moduleId = Modules.Documentation;

const mapStateToProps = (state: StateReducer) => ({
	documentsCollection: state.documentsCollection,
	user: state.user,
	currentUser: state.users,
	language: state.language,
	companies: state.companyNames,
	tradeCompanies: state.tradeCompanies,
	business: state.businessNames,
	countries: state.countries,
	originals: state.originals,
    documentTypes: state.documentTypePrimaries,
    documentSubtypes: state.documentTypeSubTypes,
	filter: state.documentationFilter
});

const mapDispatchToProps = (dispatch: any) => ({
	getDocumentation: (filter: DocumentationFilter, pageNumber: number, itemsPage: number) => dispatch(getDocumentationAction(filter, pageNumber, itemsPage)),
	deleteDocumentation: (filter: DocumentationFilter, language: Language, id: number, currentUser: UserState) => dispatch(deleteDocumentationAction(filter, language, id, currentUser)),
	getCompanies: () => dispatch(getCompaniesNamesAction(moduleId)),
	getTradeCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getBusiness: () => dispatch(getBusinessAction()),
	getCountries: () => dispatch(getCountriesAction()),
	getOriginals: () => dispatch(getOriginalsAction()),
	getDocumentTypePrimaries: () => dispatch(getDocumentTypePrimariesAction()),
    getDocumentTypeSubTypes: () => dispatch(getDocumentTypeSubTypeAction()),
	exportToExcel: (filter: DocumentationFilter) => dispatch(exportToExcelAction(filter))
});

export const DocumentationPageContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentationPage);
