import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { DeedType, Language, UserInfo } from '../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDeedTypeAction = (deedType: DeedType, currentLanguage: Language, currentUser: UserInfo, previousDeedType?: DeedType) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (deedType.id > 0) {
        handleUpdateDeedType(deedType, language, dispatch, currentUser, previousDeedType);
    } else {
        handleCreateDeedType(deedType, language, dispatch, currentUser);
    }
};


const handleUpdateDeedType = (deedType: DeedType, language: any, dispatch, currentUser: UserInfo, previousDeedType: DeedType) => {
    GenericApi.UpdateAsync(ApiUrls.DeedTypes, deedType).then((result: any) => {
        if (result.error) {
            showError(language['deedType']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDeedTypeCompleted(result));
        }
        else {
            showSuccess(language['deedType']['messages']['success-update']);
            browserHistory.goBack();
            const trazaDeed = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Deed",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado la escritura ${deedType.id}, con los siguientes cambios: ${objectToString(compareObjects(previousDeedType, deedType))}`,
                IdRegistro: deedType.id.toString(), 
                RegistroActual: objectToString(deedType),
                RegistroPrevio: objectToString(previousDeedType)
            }
            SendTraceToBBDD(trazaDeed)
            dispatch(saveDeedTypeCompleted(result));
        }
    });
}

const handleCreateDeedType = (deedType: DeedType, language: any, dispatch, currentUser: UserInfo) => {
    GenericApi.AddAsync(ApiUrls.DeedTypes, deedType).then((result: any) => {
        if (result > 0) {
            showSuccess(language['deedType']['messages']['success']);
            browserHistory.goBack();
            dispatch(saveDeedTypeCompleted(result));
            deedType.id = result;
            const trazaDeed = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Create DeedType",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el tipo de escritura: ${result}`,
                IdRegistro: deedType.id.toString(), 
            }
            SendTraceToBBDD(trazaDeed)
        } else {
            showError(language['deedType']['messages']['error'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDeedTypeCompleted(result));
        }
    });
}

const saveDeedTypeCompleted = (result: number) => ({
    type: actionTypes.SAVE_DEED_TYPE_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});