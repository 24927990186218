import * as React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router';
import { User, Language, DocumentsCollection, OrderBy, DocumentBase, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { languages } from '../../../common/const/resources';
import { DefaultButton } from 'office-ui-fabric-react/lib/components/Button/DefaultButton/DefaultButton';
import * as utils from '../../../utils/extensions';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { ActiveDocumentationOrderFilter, DocumentationFilter } from '../../../model/filters';
import { routeUrls } from '../../../common/const/routes';
import { ITooltipProps, TooltipHost } from 'office-ui-fabric-react';

export interface IProps {
	documentsCollection: DocumentsCollection;
	user: User;
	language: Language;
	filter: DocumentationFilter;
	page: number;
	currentUser: UserState;
	handleItemsPerPageChange: (itemsPage: number) => void;
	getDocumentation: (filter: DocumentationFilter, pageNumber: number, itemsPage: number) => Promise<DocumentsCollection>;
	deleteDocumentation: (filter: DocumentationFilter, language: Language, id: number, currentUser: UserState) => Promise<boolean>;
	exportToExcel: (filter: DocumentationFilter) => Promise<any>;
	onchangeFilter: any;
}

export interface IState {
	showModal: boolean;
	id: number;
	isAuthorized: boolean;
	isLoading: boolean;
	collapsed: boolean;
	actualFilter: DocumentationFilter;
	orderByCompany: string;
	orderByDocType: string;
	orderByDocSubtype: string;
	orderByDate: string;
	orderByCopy: string;
	orderByCountry: string;
	orderByProject: string;
	orderByRemark: string;
}

class DocumentationDataGrid extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			showModal: false,
			isAuthorized: false,
			isLoading: true,
			collapsed: false,
			actualFilter: this.props.filter,
			orderByCompany: 'ms-Icon--ChevronUpSmall',
			orderByDocType: 'ms-Icon--ChevronUpSmall',
			orderByDocSubtype: 'ms-Icon--ChevronUpSmall',
			orderByDate: 'ms-Icon--ChevronUpSmall',
			orderByCopy: 'ms-Icon--ChevronUpSmall',
			orderByCountry: 'ms-Icon--ChevronUpSmall',
			orderByProject: 'ms-Icon--ChevronUpSmall',
			orderByRemark: 'ms-Icon--ChevronUpSmall',
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}

	public render() {
		let isAdmin = utils.isInRole(userRoles.admin);
		const { actualFilter } = this.state;
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let optionText: string = language['generic']['list']['table']['options']['edit'];
		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.documentsCollection.totalNumberOfRows > 1 ? (
								<span>{this.props.documentsCollection.totalNumberOfRows} {language['generic']['list']['table']['results']}</span>
							) : this.props.documentsCollection.totalNumberOfRows === 1 ? (
								<span>{this.props.documentsCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.documentsCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['company']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDocumentationOrderFilter.Company === actualFilter.CurrentFilter &&
													actualFilter.CompanyOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDocumentationOrderFilter.Company === actualFilter.CurrentFilter &&
													actualFilter.CompanyOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['docType']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${(ActiveDocumentationOrderFilter.DocTypeSpanish === actualFilter.CurrentFilter || ActiveDocumentationOrderFilter.DocTypeEnglish === actualFilter.CurrentFilter) &&
													actualFilter.DocTypeOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDocType}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${(ActiveDocumentationOrderFilter.DocTypeSpanish === actualFilter.CurrentFilter || ActiveDocumentationOrderFilter.DocTypeEnglish === actualFilter.CurrentFilter) &&
													actualFilter.DocTypeOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDocType}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['docSubtype']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${(ActiveDocumentationOrderFilter.DocSubtypeSpanish === actualFilter.CurrentFilter || ActiveDocumentationOrderFilter.DocSubtypeEnglish === actualFilter.CurrentFilter) &&
													actualFilter.DocSubtypeOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDocSubtype}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${(ActiveDocumentationOrderFilter.DocSubtypeSpanish === actualFilter.CurrentFilter || ActiveDocumentationOrderFilter.DocSubtypeEnglish === actualFilter.CurrentFilter) &&
													actualFilter.DocSubtypeOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDocSubtype}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['date']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDocumentationOrderFilter.Date === actualFilter.CurrentFilter &&
													actualFilter.DateOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDate}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDocumentationOrderFilter.Date === actualFilter.CurrentFilter &&
													actualFilter.DateOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDate}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['copy']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDocumentationOrderFilter.Copy === actualFilter.CurrentFilter &&
													actualFilter.CopyOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCopy}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDocumentationOrderFilter.Copy === actualFilter.CurrentFilter &&
													actualFilter.CopyOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCopy}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['observations']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${(ActiveDocumentationOrderFilter.Comments === actualFilter.CurrentFilter ) &&
													actualFilter.CommentsOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByComments}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${(ActiveDocumentationOrderFilter.Comments === actualFilter.CurrentFilter) &&
													actualFilter.CommentsOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByComments}
											></i>
										</div>
									</div>	
								</th>						
								<th>
									<div className='table-header'>
										<span>{language['documentation']['list']['grid']['remark']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDocumentationOrderFilter.Remark === actualFilter.CurrentFilter &&
													actualFilter.RemarkOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByRemark}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDocumentationOrderFilter.Remark === actualFilter.CurrentFilter &&
													actualFilter.RemarkOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByRemark}
											></i>
										</div>
									</div>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.documentsCollection?.list.map((item: DocumentBase) => {
								let hasTradeId=false;
								this.props.currentUser.currentUser?.roles.forEach(function (value) {
									let roleNumber=utils.getBusinessNewPowerByRole(value);
									if(roleNumber===item.tradeId){
										hasTradeId=true;
									}
								}); 

								let isBusinessItem = (!isAdmin && !hasTradeId);

								//let isBusinessItem = userBusinessId > 0 && userBusinessId?.toString() !== item.tradeId?.toString();
								let tooltipRemarkProps: ITooltipProps = {
									onRenderContent: () => (
										<>
											{item.remark}
										</>
									),
								};
								return (
									<tr>
										{this.props.language.name.includes(ActiveLanguage.English) ?
											<>
												<td>{item.companyName}</td>
												<td>{item.documentationTypeNameEnslish}</td>
												<td>{item.subDocumentationTypeNameEnslish}</td>
												{/* <td>{utils.getParsedDate(item.date).toLocaleDateString('es-ES')}</td> */}
												<td>{utils.formatDate(item.date, this.props.language)}</td>
												<td>{item.copy}</td>
												<td>{item.comments}</td>
												<td>
													<TooltipHost tooltipProps={tooltipRemarkProps}>
														{!utils.isNullOrEmpty(item.remark) && item.remark.length >= 40 ? item.remark.substring(0, 40) + '...' : item.remark}
													</TooltipHost>
												</td>
											</>
											:
											<>
												<td>{item.companyName}</td>
												<td>{item.documentationTypeNameSpanish}</td>
												<td>{item.subDocumentationTypeNameSpanish}</td>
												{/* <td>{utils.getParsedDate(item.date).toLocaleDateString('es-ES')}</td> */}
												<td>{utils.formatDate(item.date, this.props.language)}</td>
												<td>{item.copy}</td>
												<td>{item.comments}</td>
												<td>
													<TooltipHost tooltipProps={tooltipRemarkProps}>
														{!utils.isNullOrEmpty(item.remark) && item.remark.length >= 40 ? item.remark.substring(0, 40) + '...' : item.remark}
													</TooltipHost>
												</td>
											</>
										}

										<td className='deleteItem'>
											<div>
												<DefaultButton
													text='. . .'
													disabled={isBusinessItem}
													menuProps={{
														shouldFocusOnMount: true,
														items: [
															{
																key: 'view',
																onClick: () => {
																	this.handleViewClick(item.id);
																},
																iconProps: {
																	iconName: 'View',
																	className: 'context-menu__icon',
																},
																name: language['generic']['list']['table']['options']['view'],
															},
															{
																key: 'edit',
																onClick: () => {
																	this.props.history.push('/documentation/edit/' + item.id);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: optionText,
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.id);
																},
																name: language['generic']['list']['table']['options']['delete'],
															},
														],
													}}
													className='tdNoBorder'
												/>
												<DialogComponent
													showModal={this.state.showModal}
													TitleDialog={language['generic']['dialog']['title']}
													QuestionDialog={language['generic']['dialog']['question']}
													onSuccess={() => {
														this.Delete();
													}}
													onFail={() => {
														this.Fail();
													}}
													CancelTitle={language['generic']['dialog']['cancel']}
													SuccessTitle={language['generic']['dialog']['confirm']}
												/>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<AdvancedPagination
					currentPage={this.props.page}
					items={this.props.documentsCollection.totalNumberOfRows}
					link={routeUrls.documentationPagination}
					updateMaxPageItems={this.handleItemsPerPageChange}
					actualPageItems={this.state.actualFilter.ItemsPage}
					language={this.props.language}
				/>
			</>
		);
	}

	private readonly Delete = () => {
		this.props.deleteDocumentation(this.props.filter, this.props.language, this.state.id, this.props.currentUser);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}

	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}

	public handleViewClick(id: number) {
		this.props.history.push('/documentation/view/' + id);
	}

	public readonly handleOrderByCompany = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.Company;
		let orderBy: string = '';

		if (filter.CompanyOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CompanyOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CompanyOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCompany: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDocType = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.DocTypeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveDocumentationOrderFilter.DocTypeEnglish;
		}

		let orderBy: string = '';

		if (filter.DocTypeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DocTypeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DocTypeOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDocType: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDocSubtype = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.DocSubtypeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveDocumentationOrderFilter.DocSubtypeEnglish;
		}

		let orderBy: string = '';

		if (filter.DocSubtypeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DocSubtypeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DocSubtypeOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDocSubtype: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDate = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.Date;
		let orderBy: string = '';

		if (filter.DateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DateOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByCopy = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.Copy;
		let orderBy: string = '';

		if (filter.CopyOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CopyOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CopyOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCopy: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByCountry = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.CountrySpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveDocumentationOrderFilter.CountryEnglish;
		}

		let orderBy: string = '';

		if (filter.CountryOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CountryOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CountryOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCountry: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByProject = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.Project;
		let orderBy: string = '';

		if (filter.ProjectOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.ProjectOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.ProjectOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByProject: orderBy });
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByComments = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.Comments;
		let orderBy: string = '';

		if (filter.CommentsOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CommentsOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CommentsOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByProject: orderBy });
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByRemark = (event) => {
		let filter: DocumentationFilter = this.props.filter;
		filter.CurrentFilter = ActiveDocumentationOrderFilter.Remark;
		let orderBy: string = '';

		if (filter.RemarkOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.RemarkOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.RemarkOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByRemark: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, filter.ItemsPage);
	};

	public handleExportClick = () => {
		let filter: DocumentationFilter = this.props.filter;
		this.props.exportToExcel(filter);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: DocumentationFilter = { ...this.state.actualFilter };
		filter.ItemsPage = itemsPage
		this.setState({ actualFilter: filter });
		this.props.getDocumentation(filter, 1, itemsPage);
	};
}

export default withRouter(DocumentationDataGrid);
