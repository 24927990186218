import { connect } from 'react-redux';
import { DocumentType, Language, UserInfo } from '../../model';
import { StateReducer } from '../../reducers';
import { getDocumentTypeAction, getDocumentTypePrimariesAction, saveDocumentTypeAction } from './actions/index';
import { DocumentTypeComponent } from './crud/documentTypeComponent';

const mapStateToProps = (state: StateReducer) => ({
	user: state.user,
	language: state.language,
	documentType: state.documentType,
	documentTypeCollection: state.documentTypeCollection,
	documentTypeFilter: state.documentTypeFilter,
	documentTypeResponse: state.documentTypeResponse,
	documentTypePrimaries: state.documentTypePrimaries,
	loading: state.http?.inProgress,
});

const mapDispatchToProps = (dispatch: any) => ({
	getDocumentType: (id: number) => dispatch(getDocumentTypeAction(id)),
	getDocumentTypePrimaries: () => dispatch(getDocumentTypePrimariesAction()),
	saveDocumentType: (documentType: DocumentType, language: Language, currentUser: UserInfo, previousDocumentType?: DocumentType) => dispatch(saveDocumentTypeAction(documentType, language, currentUser, previousDocumentType)),
});

export const DocumentTypeContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentTypeComponent);
