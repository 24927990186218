import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { Constants } from '../../../common/const/constants';
import { Language, UserInfo } from '../../../model';
import { DepartmentFilter } from '../../../model/filters';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
import { getDepartmentCollectionAction } from '../actions/getDepartmentCollection';

export const deleteDepartmentAction = (filter: DepartmentFilter, currentLanguage: Language, id: number, currentUser: UserInfo) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.Department, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['departments']['messages']['success-delete']);
			const trazaDepartment = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Delete Department",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el departamento con id: ${id}`,
                IdRegistro: id.toString(), 
            }
            SendTraceToBBDD(trazaDepartment)
		} else {
			showError(language['departments']['messages']['error-delete']);
		}

		dispatch(getDepartmentCollectionAction(filter, 1, Constants.ItemsPerPage));
		dispatch(deleteDepartmentCompleted(result));
	});
};
const deleteDepartmentCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_DEPARTMENT,
	payload: result,
});
