import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { Constants } from '../../common/const/constants';
import { runtimeVariables } from '../../globalEnvVariables';
import { CompanyCollection, RecordCompanyCustom } from '../../model/';

import { ActiveRecordCompanyOrderFilter, RecordCompanyDetailFilter, RecordCompanyFilter } from '../../model/filters/';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';

// let pageRender: number = 0;		Clean Warning Code 

const FilterAsync = async (filter: RecordCompanyFilter, pageNumber: number): Promise<CompanyCollection> => {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/ListRecordCompany`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	// pageRender = pageNumber;		Clean Warning Code 

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${Constants.ItemsPerPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveRecordCompanyOrderFilter.ConstitutionDate:
			queryString += '&ConstitutionDateOrder=';
			if (filter.ConstitutionDateOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRecordCompanyOrderFilter.SocialAddress:
			queryString += '&SocialAddressOrder=';
			if (filter.SocialAddressOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveRecordCompanyOrderFilter.Cif:
			queryString += '&CifOrder=';
			if (filter.CifOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&CompanyNameOrder=';
			if (filter.CompanyNameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
	}

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}
	if (filter.LegalFormId > 0) {
		queryString += '&LegalFormId=' + filter.LegalFormId;
	}
	if (!utils.isNullOrEmpty(filter.Cif)) {
		queryString += '&Cif=' + encodeURIComponent(filter.Cif);
	}

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
};

async function GetRecordCompany(filter: RecordCompanyDetailFilter): Promise<RecordCompanyCustom> {
	let baseUrl = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/GetRecordCompany`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let url =
		`${baseUrl}?CompanyId=${filter.CompaniesId}` +
		`&PositionsPage=${filter.PositionsPage}` +
		`&PositionsSizePage=${filter.PositionsSizePage}` +
		`&StockholdersPage=${filter.StockholdersPage}` +
		`&StockholdersSizePage=${filter.StockholdersSizePage}` +
		`&HistoricalStockholdersPage=${filter.HistoricalStockholdersPage}` +
		`&HistoricalStockholdersSizePage=${filter.HistoricalStockholdersSizePage}`;

	return fetch(url, obj).then((response) => response.json());
}

async function ExportAdminBodyToExcel(companyId: number[]): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/ExportAdminBodyToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = '&CompanyId=' + companyId.toString();

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => utils.downloadBlob(response, 'AdminBody.xlsx'));
}

async function ExportActualStockholdersToExcel(companyId: number[]): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/ExportActualStockholdersToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = '&CompanyId=' + companyId;

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => utils.downloadBlob(response, 'ActualStockholders.xlsx'));
}

async function ExportHistoricalStockholdersToExcel(companyId: number[]): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/ExportHistoricalStockholdersToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = '&CompanyId=' + companyId;

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => utils.downloadBlob(response, 'HistoricalStockholders.xlsx'));
}

async function UpdatePowersRevokeAsync(companyId: number, revokeMsg: string, protocolNum: string): Promise<boolean> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.Company}/UpdatePowersRevoke`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = 'CompanyId=' + companyId;
	queryString = queryString + '&revokeMsg=' + revokeMsg;
	queryString = queryString + '&protocolNum=' + protocolNum;

	//let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString, obj).then((response) => response.json);
}

export const RecordCompaniesApi = {
	FilterAsync,
	GetRecordCompany,
	ExportAdminBodyToExcel,
	ExportActualStockholdersToExcel,
	ExportHistoricalStockholdersToExcel,
	UpdatePowersRevokeAsync,
};
