import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { getPositionTypeAction, savePositionTypeAction } from './actions/index';
import { getBusinessNamesAction } from './actions';
import { Language, PositionType, UserInfo } from '../../model';
import { PositionTypeComponent } from './crud/positionTypeComponent';

const mapStateToProps = (state: StateReducer) => ({
	positionTypeSave: state.positionTypeResponse,
	dpositionTypeDelete: state.positionTypeResponse,
	positionType: state.positionType,
	user: state.user,
	language: state.language,
	business: state.businessNames,
	loading: state.http?.inProgress,
});

const mapDispatchToProps = (dispatch: any) => ({
	getBusiness: () => dispatch(getBusinessNamesAction()),
	savePositionType: (positionType: PositionType, language: Language, currentUser: UserInfo, previousPositionType?: PositionType) => dispatch(savePositionTypeAction(positionType, language, currentUser, previousPositionType)),
	getPositionType: (id: number) => dispatch(getPositionTypeAction(id)),
});

export const PositionTypeContainer = connect(mapStateToProps, mapDispatchToProps)(PositionTypeComponent);
