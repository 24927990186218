import * as React from 'react';
import { languages } from '../../common/const/resources';
import { runtimeVariables } from '../../globalEnvVariables';
import { Language, User, UserState } from '../../model';
import { ActiveLanguage } from '../../model/enums/activeLanguage';

export interface IProps {
	user: User;
	users: UserState;
	userName: string;
	language: Language;
}
export class UserPage extends React.Component<IProps, {}> {
	/* constructor(props: IProps) {
		super(props);
	}		Clean Warning Code */

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				{this.props.users?.currentUser?.roles?.length > 0 ? (
					<>
						<span className='main-header__bar_user'>
							<i className='ms-Icon ms-Icon--Contact' aria-hidden='true'></i>
							{this.props.userName}
							<i
								className='ms-Icon ms-Icon--SignOut'
								aria-hidden='true'
								title='LogOut'
								onClick={() => runtimeVariables._instance.authProvider.logout()}
							></i>
						</span>
					</>
				) : (
					<>
						<span className='main-header__bar_user'>
							{language['header']['logout']}
							<i
								className='ms-Icon ms-Icon--SignOut'
								aria-hidden='true'
								title='LogOut'
								onClick={() => runtimeVariables._instance.authProvider.logout()}
							></i>
						</span>
					</>
				)}
			</>
		);
	}
}
