import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { runtimeVariables } from '../../globalEnvVariables';

import { DeedType, DeedTypeCollection, DeedTypeSubType } from '../../model/DeedType';
import { ActiveDeedTypeOrderFilter } from '../../model/filters/ActiveDeedTypeFilter';
import { DeedTypeFilter } from '../../model/filters/DeedTypeFilter';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';

async function FilterAsync(filter: DeedTypeFilter, pageNumber: number, itemsPage: number): Promise<DeedTypeCollection> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DeedTypes}/GetByFilter`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveDeedTypeOrderFilter.DescriptionSpanish:
			queryString += '&DescriptionSpanishOrder=';
			if (filter.DescriptionSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveDeedTypeOrderFilter.DescriptionEnglish:
			queryString += '&DescriptionEnglishOrder=';
			if (filter.DescriptionEnglishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveDeedTypeOrderFilter.FatherDescriptionSpanish:
			queryString += '&FatherDescriptionSpanishOrder=';
			if (filter.FatherDescriptionSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveDeedTypeOrderFilter.FatherDescriptionEnglish:
			queryString += '&FatherDescriptionEnglishOrder=';
			if (filter.FatherDescriptionEnglishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveDeedTypeOrderFilter.FatherId:
			queryString += '&FatherIdOrder=';
			if (filter.TypeOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActiveDeedTypeOrderFilter.AffectsCapital:
			queryString += '&AffectsCapitalOrder=';
			if (filter.AffectsCapitalOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&DescriptionSpanishOrder=';
			if (filter.DescriptionSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}
	if (!utils.isNullOrEmpty(filter.DescriptionSpanish)) {
		queryString += '&DescriptionSpanish=' + encodeURIComponent(filter.DescriptionSpanish) + '';
	}
	if (!utils.isNullOrEmpty(filter.DescriptionEnglish)) {
		queryString += '&DescriptionEnglish=' + encodeURIComponent(filter.DescriptionEnglish) + '';
	}
	if (filter.FatherId > 0) {
		queryString += '&FatherId=' + encodeURIComponent(filter.FatherId) + '';
	}
	if (filter.AffectsCapital !== null) {
		queryString += '&AffectsCapital=' + encodeURIComponent(filter.AffectsCapital) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
}

async function ExportToExcel(filter: DeedTypeFilter): Promise<any> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DeedTypes}/ExportToExcel`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('deed-types')),
	);
}

function ExportToExcelQuery(filter: DeedTypeFilter): string {
	let queryString: string = '';

	if (filter.FatherId != null) {
		queryString += '&FatherId=' + filter.FatherId;
	}

	if (filter.DescriptionSpanish != null) {
		queryString += '&DescriptionSpanish=' + filter.DescriptionSpanish;
	}

	if (filter.DescriptionEnglish != null) {
		queryString += '&DescriptionEnglish=' + filter.DescriptionEnglish;
	}

	if (filter.AffectsCapital != null) {
		queryString += '&AffectsCapital=' + filter.AffectsCapital;
	}

	return queryString;
}

async function GetDeedTypePrimaries(): Promise<DeedType[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DeedTypes}/GetListDeedTypePrimary`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + randomGuid, obj).then((response) => response.json());
}
async function GetDeedTypeSubType(): Promise<DeedTypeSubType[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${ApiUrls.DeedTypes}/GetListDeedSubType`;
	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + randomGuid, obj).then((response) => response.json());
}
export const DeedTypeApi = {
	FilterAsync,
	ExportToExcel,
	GetDeedTypePrimaries,
	GetDeedTypeSubType,
};
