import { GenericApi, SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { DocumentType, Language, UserInfo } from '../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDocumentTypeAction = (documentType: DocumentType, currentLanguage: Language, currentUser: UserInfo, previousDocumentType?: DocumentType) => (dispatch) => {
	dispatch(httpCallStartAction());
	let language = getResourcesFile(currentLanguage);
	if (documentType.id > 0) {
		handleUpdateDocumentType(documentType, language, dispatch, currentUser, previousDocumentType);
	} else {
		handleCreateDocumentType(documentType, language, dispatch, currentUser);
	}
};

const handleUpdateDocumentType = (documentType: DocumentType, language: any, dispatch, currentUser: UserInfo, previpusDocumentType: DocumentType) => {
	GenericApi.UpdateAsync(ApiUrls.DocumentTypes, documentType).then((result: any) => {
		if (result.error) {
			showError(language['documentType']['messages']['error-update'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveDocumentTypeCompleted(result));
		} else {
			showSuccess(language['documentType']['messages']['success-update']);
			browserHistory.goBack();
			dispatch(saveDocumentTypeCompleted(result));
			const trazaDocumentType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Document Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha modificado la persona ${documentType.id}, con los siguientes cambios: ${objectToString(compareObjects(documentType, previpusDocumentType))}`,
                IdRegistro: documentType.id.toString()
            }
            SendTraceToBBDD(trazaDocumentType)
			
		}
	});
};

const handleCreateDocumentType = (documentType: DocumentType, language: any, dispatch, currentUser: UserInfo) => {
	GenericApi.AddAsync(ApiUrls.DocumentTypes, documentType).then((result: any) => {
		if (result > 0) {
			showSuccess(language['documentType']['messages']['success']);
			browserHistory.goBack();
			dispatch(saveDocumentTypeCompleted(result));
			documentType.id = result;
			const trazaDocumentType = {
                Usuario: currentUser.mail,
                Grupos: currentUser.roles.join(','),
                Accion: "Update Document Type",
                Fecha: new Date().toDateString(),
                Traza: `Se ha creado el tipo de documento ${result}`,
                IdRegistro: documentType.id.toString()
            }
            SendTraceToBBDD(trazaDocumentType)
		} else {
			showError(language['documentType']['messages']['error'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveDocumentTypeCompleted(result));
		}
	});
};

const saveDocumentTypeCompleted = (result: number) => ({
	type: actionTypes.SAVE_DOCUMENT_TYPE_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
