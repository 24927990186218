import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { Language, NotaryPeople, NotaryPeopleCollection, OrderBy, User, UserState } from '../../../model/';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveNotaryPeopleOrderFilter, NotaryPeopleFilter } from '../../../model/filters';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { MeApi } from '../../../api/me';

export interface IProps {
	notaryPeopleCollection: NotaryPeopleCollection;
	user: User;
	language: Language;
	page: number;
	onchangeFilter: any;
	filter: NotaryPeopleFilter;
	getNotaryPeopleCollection: (filter: NotaryPeopleFilter, pageNumbers: number, itemsPage: number) => Promise<NotaryPeopleCollection>;
	deleteNotaryPeople: (filter: NotaryPeopleFilter, language: Language, id: number, currentUser: UserState) => Promise<boolean>;
	exportToExcel: (filter: NotaryPeopleFilter) => Promise<any>;
}

export interface IState {
	showModal: boolean;
	id: number;
	collapsed: boolean;
	filter: NotaryPeopleFilter;
	orderByName: string;
	orderByEmail: string;
}

class NotaryPeopleDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			id: 0,
			collapsed: false,
			filter: this.props.filter,
			orderByName: 'ms-Icon--ChevronUpSmall',
			orderByEmail: 'ms-Icon--ChevronUpSmall'
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			let filter: NotaryPeopleFilter = {...this.props.filter}
			filter.Name = "";
			filter.NotaryPeopleId = 0;
			this.setState({ filter: this.props.filter });
		}
	}

	public render() {
		const { filter } = this.state;
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let optionText: string = language['notaryPeople']['list']['table']['options']['edit'];

		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.notaryPeopleCollection.totalNumberOfRows > 0 ? (
								<span>
									{this.props.notaryPeopleCollection.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.notaryPeople.totalNumberOfRows === 1 ? (
								<span>
									{this.props.notaryPeopleCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.notaryPeopleCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['notaryPeople']['list']['table']['columns']['name']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveNotaryPeopleOrderFilter.Name === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveNotaryPeopleOrderFilter.Name === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
										</div>
									</div>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								this.props.notaryPeopleCollection?.list.map((item: NotaryPeople) => {

									let notaryName = item.name?.length > 35 ? item.name.substr(0, 32) + "..." : item.name;

									return (
										<tr>
											<td title={item.name?.length > 35 ? item.name : ''} className='mainColumn'>{notaryName}</td>
											<td className='deleteItem'>
												<div>
													<DefaultButton
														text='. . .'
														disabled={!isAdmin}
														menuProps={{
															shouldFocusOnMount: true,
															items: [
																{
																	key: 'Edit',
																	onClick: () => {
																		this.props.history.push('/notaryPeople/edit/' + item.id);
																	},
																	iconProps: {
																		iconName: 'Edit',
																		className: 'context-menu__icon',
																	},
																	name: optionText,
																},
																{
																	key: 'delete',
																	iconProps: {
																		iconName: 'Delete',
																		className: 'context-menu__icon',
																	},
																	onClick: () => {
																		this.ShowModal(item.id);
																	},
																	name: language['notaryPeople']['list']['table']['options']['delete'],
																},
															],
														}}
														className='tdNoBorderAlignRight'
													/>
													<DialogComponent
														showModal={this.state.showModal}
														TitleDialog={language['generic']['dialog']['title']}
														QuestionDialog={language['generic']['dialog']['question']}
														onSuccess={() => {
															this.Delete();
														}}
														onFail={() => {
															this.Fail();
														}}
														CancelTitle={language['generic']['dialog']['cancel']}
														SuccessTitle={language['generic']['dialog']['confirm']}
													/>
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.notaryPeopleCollection.totalNumberOfRows}
						link={routeUrls.notaryPeoplePagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.filter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}
	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}
	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}
	public readonly Delete = (): Promise<Boolean> => {

		return MeApi.GetUserInfo().then((user) => {
			return this.props.deletePerson(this.state.filter, this.props.language, this.state.id, user);
		});

	};

	public readonly handleOrderByName = (event) => {
		let filter: NotaryPeopleFilter = this.props.filter;
		filter.CurrentFilter = ActiveNotaryPeopleOrderFilter.Name;
		let orderBy: string = '';

		if (filter.NameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.NameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.NameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByName: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByEmail = () => {
		let filter: NotaryPeopleFilter = this.props.filter;
		filter.CurrentFilter = ActiveNotaryPeopleOrderFilter.Email;
		let orderBy: string = '';

		if (filter.EmailOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.EmailOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.EmailOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByEmail: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public handleExportClick = () => {
		let filter: NotaryPeopleFilter = { ...this.state.filter };
		
		this.props.exportToExcel(filter);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: NotaryPeopleFilter = { ...this.state.filter };
		filter.ItemsPage = itemsPage;
		this.setState({ filter });
		this.props.getPeople(filter, 1, itemsPage);
	};
}
export const NotaryPeopleDataGrid = withRouter(NotaryPeopleDataGridComponent);