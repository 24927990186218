import { TooltipHost } from 'office-ui-fabric-react';
import * as React from 'react';
import { BaseType, Language, PowerRepresentativeDetail } from '../../../../model';
import '../../../../styles/pages/representatives.scss';
import { getResourcesFile } from '../../../../utils/extensions';
import { RepresentativesGrid } from './Grids/representativesGrid';

interface IProps {
	representatives: PowerRepresentativeDetail[];
	powerTypes: BaseType[];
	language: Language;
	powerExerciseNames: BaseType[];
	departments: BaseType[];
	editRepresentative: (representative: PowerRepresentativeDetail, index: number, isEdit: boolean) => void;
	updateRepresentatives: (representatives: PowerRepresentativeDetail[], trazas: string) => void;
	showForm: () => void;
	isReadOnly: boolean;
	index: number;
	documentDate: Date;
	trazas: string;
	cancelEditRepresentative: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
}

export class RepresentativesTab extends React.Component<IProps, {}> {
	public render() {
		let grid = (
			<RepresentativesGrid
				deleteRepresentative={this.deleteRepresentative}
				powerTypes={this.props.powerTypes}
				representatives={this.props.representatives}
				language={this.props.language}
				editRepresentative={this.props.editRepresentative}
				powerExerciseNames={this.props.powerExerciseNames}
				isReadOnly={this.props.isReadOnly}
				departments={this.props.departments}
				index={this.props.index}
				cancelEditRepresentative={this.props.cancelEditRepresentative}
				setFinishedEdit={this.props.setFinishedEdit}
			/>
		);
		let language = getResourcesFile(this.props.language);
		return (
			<div className='ms-Grid-col ms-md9'>
				<div>
					<table className='internal-table'>
						<thead>
							<tr className='main-container__content__table-navigationRow'>							
								<th>
									<TooltipHost content={language['representatives']['table']['name']}>
										<label>{language['representatives']['table']['name']}</label>
									</TooltipHost>
								</th>
								
								<th>
									<TooltipHost content={language['representatives']['table']['exerciseType']}>
										<label>{language['representatives']['table']['exerciseType']}</label>
									</TooltipHost>
								</th>
								<th>
									<TooltipHost content={language['representatives']['table']['grantRevoke']}>
										<label>{language['representatives']['table']['grantRevoke']}</label>
									</TooltipHost>
								</th>
								<th>
									<TooltipHost content={language['representatives']['table']['endDate']}>
										<label>{language['representatives']['table']['endDate']}</label>
									</TooltipHost>
								</th>
								<th className='endDateHeader'>
									<TooltipHost content={language['representatives']['table']['department']}>
										<label>{language['representatives']['table']['department']}</label>
									</TooltipHost>
								</th>
								<th className='removeButton'>
									<label className='removeButtonContainer'>&nbsp;</label>
								</th>
							</tr>
						</thead>
						{grid}
					</table>
				</div>
			</div>
		);
	}
	
	public readonly deleteRepresentative = (representative: PowerRepresentativeDetail) => {
		let representatives = this.props.representatives.slice();
		let representativeIndex = representatives.findIndex((item) => item === representative);
		let trazaBorradoRepresentative = `Se ha borrado el apoderado con id ${representative.id}`
		representatives.splice(representativeIndex, 1);
		this.props.updateRepresentatives(representatives, trazaBorradoRepresentative);
	};
}
