import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { NotaryPeoplePage } from './page';
import { getNotaryPeopleAction } from './actions/getNotaryPeople';
import { deleteNotaryPeopleAction } from './actions/deleteNotaryPeople';
import { NotaryPeopleFilter } from '../../../model/filters/NotaryPeopleFilter';
import { Language, UserInfo, UserState } from '../../../model';
import { exportToExcelAction } from './actions/exportToExcel';
import { getNotaryPeopleNamesAction } from './actions/getNotaryPeopleNames';

const mapStateToProps = (state: StateReducer) => ({
	notaryPeopleCollection: state.notaryPeopleCollection,
	csvDownloadLink: state.csvDownloadLink,
	user: state.user,
	language: state.language,
	business: state.businessNames,
	currentUser: state.users,
	notaryPeopleNames: state.notaryPeopleNames,
	filter: state.notaryPeopleFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	getNotaryPeople: (filter: NotaryPeopleFilter, pageNumber: number, itemsPage: number) => dispatch(getNotaryPeopleAction(filter, pageNumber, itemsPage)),
	deleteNotaryPeople: (filter: NotaryPeopleFilter, language: Language, id: number, currentUser: UserInfo) => dispatch(deleteNotaryPeopleAction(filter, language, id, currentUser)),
	exportToExcel: (filter: NotaryPeopleFilter) => dispatch(exportToExcelAction(filter)),
	getNotaryPeopleNames: (filter: string) => dispatch(getNotaryPeopleNamesAction(filter)),
});

export const NotaryPeoplePageContainer = connect(mapStateToProps, mapDispatchToProps)(NotaryPeoplePage);