import { connect } from 'react-redux';
import { Language, UserState } from '../../../model';
import { DeedFilter } from '../../../model/filters';
import { StateReducer } from '../../../reducers';
import { getDeedTypePrimariesAction } from '../list/actions/getDeedTypePrimaries';
import { getDeedTypeSubTypesAction } from '../list/actions/getDeedTypeSubTypes';
import { getBusinessNamesAction } from '../list/actions/getBusiness';
import { deleteDeedAction } from './actions/deleteDeed';
import { exportToExcelAction } from './actions/exportToExcel';
import { getDeedsAction } from './actions/getDeeds';
import { getOriginalsAction } from './actions/getOriginals';
import { setDeedFilterAction } from './actions/setDeedFilter';
import { DeedsPage } from './page';
import {getTradeCompaniesAction} from './actions/getCompanies'
import { Modules } from '../../../model/enums';
import { getNotaryPeopleNamesAction } from './actions/getNotaryPeopleNames';
import { getCompaniesNamesAction } from './actions/getCompaniesNamesAction';

const moduleId = Modules.Deeds;

const mapStateToProps = (state: StateReducer) => ({
	companies: state.companyNames,
	tradeCompanies: state.tradeCompanies,
	deedCollection: state.deeds,
	deedTypePrimaries: state.deedTypePrimaries,
	deedTypeSubTypes: state.deedTypeSubTypes,
	user: state.user,
	language: state.language,
	csvDownloadLink: state.csvDownloadLink,
	currentUser: state.users,
	filter: state.deedFilter,
	originals: state.originals,
	business: state.businessNames,
	notaryPeopleNames: state.notaryPeopleNames
});

const mapDispatchToProps = (dispatch: any) => ({
	getDeeds: (filter: DeedFilter, pageNumber: number, itemsPage: number) => dispatch(getDeedsAction(filter, pageNumber, itemsPage)),
	getCompanies: () => dispatch(getCompaniesNamesAction(moduleId)),
	getTradeCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getDeedTypePrimaries: () => dispatch(getDeedTypePrimariesAction()),
	getDeedTypeSubTypes: () => dispatch(getDeedTypeSubTypesAction()),
	setDeedFilter: (filter: DeedFilter) => dispatch(setDeedFilterAction(filter)),
	exportToExcel: (filter: DeedFilter) => dispatch(exportToExcelAction(filter)),
	getOriginals: () => dispatch(getOriginalsAction()),
	deleteDeed: (filter: DeedFilter, language: Language, id: number, currentUser: UserState) => dispatch(deleteDeedAction(filter, language, id, currentUser)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
	getNotaryPeopleNames: (filter: string) => dispatch(getNotaryPeopleNamesAction(filter))	
});

export const DeedsListPageContainer = connect(mapStateToProps, mapDispatchToProps)(DeedsPage);
