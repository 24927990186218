import { DeedApi } from '../../../../api/deed';
import { SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { Language, UserState } from '../../../../model';
import { DeedFilter } from '../../../../model/filters/DeedFilter';
import { getResourcesFile, showSuccess } from '../../../../utils/extensions';
import { getDeedsAction } from './getDeeds';

export const deleteDeedAction = (filter: DeedFilter, currentLanguage: Language, id: number, currentUser: UserState) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    DeedApi.DeleteAsync(ApiUrls.Deed, id).then((result) => {
        if (result) {
            showSuccess(language['deeds']['form']['messages']['success-delete']);
        } else {
            showSuccess(language['deeds']['form']['messages']['error-delete']);
        }

        dispatch(getDeedsAction(filter, 1, 10));
        dispatch(deleteDeedCompleted(result));
        const trazaDeed = {
            Usuario: currentUser.currentUser.email,
            Grupos: currentUser.currentUser.roles.join(','),
            Accion: "Create Deed",
            Fecha: new Date().toDateString(),
            Traza: `Se ha creado la escritura ${id}`,
            IdRegistro: id.toString(),
        }
        SendTraceToBBDD(trazaDeed)

    });

};
const deleteDeedCompleted = (result: boolean) => ({
    type: actionTypes.DELETE_DEED,
    payload: result
});