import * as fetch from 'isomorphic-fetch';
import { runtimeVariables } from '../../globalEnvVariables';

import * as utils from '../../utils/extensions';

async function GetAllAsync<T>(baseUrl: string): Promise<T[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/GetAll`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let randomGuid: string = utils.generateRandomGuid(true);

	return fetch(baseURL + randomGuid, obj).then((response) => response.json());
}

async function GetWithoutServices<T>(baseUrl: string): Promise<T[]> {
	let baseURL = `${runtimeVariables._instance.REACT_APP_API}/api/Catalog/${baseUrl}/GetWithoutServices`;

	const token = await runtimeVariables._instance.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let randomGuid: string = utils.generateRandomGuid(true);

	return fetch(baseURL + randomGuid, obj).then((response) => response.json());
}


export const TradeApi = {
	GetAllAsync,
	GetWithoutServices
};
